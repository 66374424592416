<template>
  <div id="search">
    <SaveLinkInfo></SaveLinkInfo>
    <div class="container-fluid mt-3">
      <div class="row align-items-top justify-content-center">
        <div class="col-12 col-sm-10">

          <!-- Results -->
          <div v-if="matches == true" id="results" class="row align-items-center justify-content-center text-left">
            <div class="col-12">
              <paginate name="dataResults" :list="dataSearch" :per="12" class="paginate-list list-unstyled">
                <div class="results-box">
                  <div class="results-line p-3 rounded" v-for="(searchData, index) in paginated('dataResults')" :key="index">
                    <div class="d-flex align-items-center justify-content-between">
                      <p class="link fs-4 fw-bold" @click="goToLink(searchData.link)">{{ searchData.name }}</p>
                      
                      <i v-if="sessionExist" class="fas fa-paperclip" :data-base="searchData.id" v-bind:class="testFav(favList,searchData.id)?'color':''" @click="goList(searchData.id)" role="button"></i>
                      <i v-else class="fas fa-paperclip opacity_50" role="button" data-bs-toggle="modal" data-bs-target="#savelink_info" disabled></i>
                    </div>
                    
                    <p class="link fs-6 mt-2" @click="goToLink(searchData.link)">{{ searchData.link }}</p>
                    <p class="txt small">{{ searchData.description }}</p>
                    <div>
                      <a v-for="(tags, index) in searchData.tags" :key="index" class="badge rounded-pill bg-dark text-sm me-1" @click="goToSearch(tags)" >{{tags}}</a>
                    </div>
                    
                  </div>
                </div>
              </paginate>
              <div class="col-12 text-center">
                <paginate-links for="dataResults" :limit="5" :show-step-links="true" class="pagination"></paginate-links>
              </div>
            </div>
          </div>

          <div v-if="matches == false" id="results" class="row align-items-center justify-content-center text-left">
            <div class="col-12 text-center mt-5">
              <div class="results-box" v-if="matches == false">
                <h5>There have been no results</h5>
              </div>
            </div>
          </div>
          <!-- END Results -->
        </div>

        <div class="col-12 col-sm-2">
          <!-- Tags bar -->
          <h5>Suggestions</h5>
          <div id="kw_pass" class="tag_box">
            <div class="tags-box">
              <span class="badge rounded-pill bg-light text-dark text-sm" v-for="(resultTagsExtra, index) in tagsExtra" :key="index" @click="goToSearch(resultTagsExtra)" >{{resultTagsExtra}}</span>
            </div>
          </div>
          <!-- END Tags bar -->
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import axios from 'axios';
import SaveLinkInfo from './modals/SaveLinkInfo.vue';

export default {
  name: "SearchTemplate",
  components: {
    SaveLinkInfo
  },
  data(){
    return {
      sessionExist: false,
      dataSearch: [],
      tags: [],
      tagsExtra: [],
      tagsInner: [],
      searchData: '',
      searchInput: '',
      submitted: false,
      matches: true,
      showTags: false,
      showTagsExtra: false,
      paginate: ['dataResults'],
      favList: [],
    }
  },
  beforeMount: function(){
    if(this.$session.exists() === true){
      this.sessionExist = true;

      // get fav
      axios.post('https://shopsuey.net/code/simply/get_user_list.php',{
        id: this.$session.get('id')
      })
      .then((response) => {
        this.favList = response.data;
      })
      .catch(function(error){
        console.log(error);
      });
    }

    this.searchData = this.$route.params.id;
    this.searchInput = this.$route.params.id;

    if(this.searchData){
      
      axios.post('https://shopsuey.net/code/simply/search_data.php',{
        search: this.searchData
      })
      .then((response) => {

        // Extraccion resultados
        if(response.data != 'ko'){
          this.dataSearch = response.data;
          
          // Extraccion etiquetas
          for (let i=0;this.dataSearch.length > i;i++){
            const b = this.dataSearch[i]['keywords'];
            this.dataSearch[i]['tags'] = [];

            for (let j=0;b.length > j;j++){
              if (b[j].indexOf(this.searchData) != '-1'){
                this.tags.push(b[j]);
                this.dataSearch[i]['tags'].push(b[j]);
                
              }else{
                this.tagsExtra.push(b[j]);
              }
            }
          }

          // Limpieza de etiquetas repetidas
          let resultTags = this.tags.filter((item,index)=>{
            return this.tags.indexOf(item) === index;
          })
          this.tags = resultTags;
          this.showTags = true;

          if(resultTags.length > 1){
            let resultTagsExtra = this.tagsExtra.filter((item,index)=>{
              return this.tagsExtra.indexOf(item) === index;
            })
            
            // Limite de resultados de sugerencias
            if(resultTagsExtra.length > 30){
              resultTagsExtra = resultTagsExtra.slice(0,30);
            }

            this.tagsExtra = resultTagsExtra;
            this.showTagsExtra = true;
          }

          this.matches = true;
          
        }else{
          this.matches = false;
        }

      })
      .then(()=>{
        let focusElement = document.getElementById('searchInput');
        focusElement.focus();
      })
      .catch(function(error){
        console.log(error);
      });

    }
  },
  
  methods: {
    goToLink(a){
      var url = a + '?ref=shopsuey';
      window.open(url, '_blank').focus();
    },
    goList(a){
      
      if(this.$session.exists() === true){

        axios.post('https://shopsuey.net/code/simply/update_user_list.php',{
          id: this.$session.get('id'),
          fav: a
        })
        .then((response) => {
          this.favList = response.data;
          this.testFav(response.data,a);
        })
        .catch(function(error){
          console.log(error);
        }); 
      }
      
    },
    testFav(a,b){
      let favList = a;
      let favId = b;
      let favResponse = false;
      for(let i=0;favList.length > i;i++) {
        if(favList[i] === favId) {
          favResponse = true;
        }
      }
      return favResponse;
    },
    close(){
      this.$session.destroy();
      this.$router.go('/');
    },
    goToSearch(a){

      this.$router.push('/search/' + a);
      this.$router.go('/search/'+ a);

    },

  }
  
};
</script>