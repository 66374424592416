<template>
  <div class="col-12">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center">
        <div class="col-8 col-md-4 text-center d-none d-md-block">
          <img src="../assets/img/ico_search_shop.png" class="img-fluid" alt="ShopSuey" />
        </div>
        <div class="col-11 col-md-8 p-5">
          
          <div class="mt-3" id="contact_form">
            <div class="row align-items-center justify-content-center box_black pt-4 pb-4">
              <div class="col-12 col-sm-12 p-4 pt-0 pb-0">
                <h2 class="text-white">{{ $t('contact.titContact') }}</h2>
                <form @submit.prevent="showmod()">
                  <div>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="type_contact" class="form-label text-white">{{ $t('form.labelTypeContact') }}</label>
                        <select id="type_contact" class="form-select" v-model="contact.type">
                          <option value="contact">{{ $t('form.optionTypeContact1') }}</option>
                          <option value="business">{{ $t('form.optionTypeContact2') }}</option>
                          <option value="bug">{{ $t('form.optionTypeContact3') }}</option>
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label for="name" class="form-label text-white">{{ $t('contact.labelName') }}</label>
                        <input type="text" class="form-control" id="name" v-model="contact.name" />
                        <div class="error_box">
                          <div class="error" v-if="submitted && !$v.contact.name.required">This field is required</div>
                          <div class="error" v-if="submitted && !$v.contact.name.minLength">It's too short</div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label for="email_verificaton" class="form-label text-white">{{ $t('contact.labelEmail') }}</label>
                        <input type="email" class="form-control" v-model="contact.email" />
                        
                        <div class="error_box">
                          <div class="error" v-if="submitted && !$v.contact.email.required">This field is required</div>
                          <div class="error" v-if="submitted && !$v.contact.email.email">This is not a valid email address</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="description" class="form-label text-white">{{ $t('contact.labelMessage') }}</label>
                    <textarea class="form-control" id="description" v-model="contact.message" maxlength="250" rows="3"></textarea>
                  </div>
                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" v-model="contact.check" id="flexCheckDefault">
                    <label class="form-check-label text-white" for="flexCheckDefault">
                      {{ $t('contact.labelPermission') }}<div class="error" v-if="submitted && !$v.contact.check.checked">This field is required</div>
                    </label>
                  </div>
                  <div class="mt-4 text-center">
                    <button type="submit" class="btn btn-primary">{{ $t('contact.btnSend') }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-11 text-center rounded box_gray_soft p-4 d-none" id="contact_form_ok">
            <h3>{{ $t('contact.formResponseTit') }}</h3>
            <p>{{ $t('contact.formResponseTxt') }}</p>
          </div>

        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-11 col-md-8 mt-3">
          <h2>{{ $t('contact.titWhat') }}</h2>
          <h3>{{ $t('contact.titBug') }}</h3>
          <p>{{ $t('contact.txtSendto') }}<a href="mailto:bug@shopsuey.net">bug@shopsuey.net</a></p>
          <div class="mini_spacer"></div>
          <h3>{{ $t('contact.titBusiness') }}</h3>
          <p>{{ $t('contact.txtSendto') }}<a href="mailto:business@shopsuey.net">business@shopsuey.net</a></p>
          <div class="mini_spacer"></div>
          <h3>{{ $t('contact.titWeather') }}</h3>
          <p>{{ $t('contact.txtSendto') }}<a href="mailto:info@shopsuey.net">info@shopsuey.net</a></p>
          <div class="mini_spacer"></div>
          <h3>{{ $t('contact.titFollow') }}</h3>
          <p>{{ $t('contact.txtFollow') }}<a href="https://twitter.com/ShopSueyNet">@ShopSueyNet</a></p>
        </div>
        <div class="col-8 col-md-4 text-center d-none d-md-block">
          <img src="../assets/img/ico_online_shop.png" class="img-fluid" alt="ShopSuey" />
        </div>
      </div>  

    </div>
      
    <div class="spacer"></div>
  </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "ContactTemplate",
  validations: {
    contact: {
      name: {
        required,
        minLength: minLength(2)
      },
      type: {
        required
      },
      email: {
        required,
        minLength: minLength(5),
        email
      },
      check: {
        checked: value => value === true
      }
    }
  },
  data(){
    return {
      submitted: false,
      contact: {
        name: '',
        type: 'contact',
        email: '',
        message: '',
        check: false
      },
    }
  },
  methods: {
    showmod(){

      this.submitted = true;

      this.$v.$touch();

      console.log(this.$v.contact.$model.name,this.$v.contact.$model.type,this.$v.contact.$model.email,this.$v.contact.$model.message);

      if(this.$v.contact.$invalid == false && this.$v.contact.$model.check == true){
        axios.post('https://shopsuey.net/code/simply/contact.php',{
          name: this.$v.contact.$model.name,
          type: this.$v.contact.$model.type,
          email: this.$v.contact.$model.email,
          message: this.$v.contact.$model.message,
        })
        .then((response)=>{
          console.log(response);
          // if (response.data == 'ok'){
          //   let divKo = document.getElementById('contact_form');
          //   let divOk = document.getElementById('contact_form_ok');
          //   divKo.remove();
          //   divOk.classList.remove('d-none');
          // }else{
          //   console.log(response);
          // }
          
        })
        .catch(function(error){
          console.log(error);
        });  
      }else{
        console.log('Tas equivocao!! xD');
      }

    }
  }
};
</script>