<template>
  <div class="col-12" id="big-container-content">
    
    <password></password>

    <div class="row align-items-center justify-content-center">
      <div class="col-sm-10 col-12 mb-4">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">

            <!-- Verification div -->
            <div id="top_validation" class="d-none">
              <div v-if="userForm.emailVal == 0">
                <h3>{{ $t('user.home.titValidate') }}</h3>
                <div class="col-12 p-4 mb-3 form_box_gray_soft">
                  <div class="row align-items-top justify-content-center">
                    <div class="col-12 text-center">
                      <p>{{ $t('user.home.txtValidate') }}</p>
                    </div>
                    <div class="col-sm-6 text-center">
                      <h4>{{ $t('user.home.titEmailVal') }}</h4>
                      <div class="col-12">
                        <div v-if="userForm.emailVal == 0 && submittedResendEmailValidation == false">
                          <p class="p-0 m-0 text-white text-center bg-primary p-3 rounded">{{ $t('user.home.txtEmailValA') }}</p>
                          <button class="btn btn-primary btn-sm mt-2" @click="sendVerified()">{{ $t('user.home.btnResend') }}</button>
                        </div>
                        <div v-else-if="userForm.emailVal == 0 && submittedResendEmailValidation == true">
                          <p class="p-0 m-0 text-white text-center bg-primary p-3 rounded">{{ $t('user.home.txtCheckEmail') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
            <!-- END Verification div -->

            <!-- User form -->

            <form @submit.prevent="updateUserData()" @change="formChanges('user')">
              <h3>{{ $t('user.home.titUser') }}</h3>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <h4>{{ $t('user.home.titAccess') }}</h4>
                <div class="row align-items-top justify-content-center">
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="email_user" class="form-label">{{ $t('user.home.labelEmail') }}</label>
                    <input type="text" class="form-control" id="email_user" v-model="userForm.email" />
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="email_admin" class="form-label">{{ $t('user.home.labelPsw') }}</label>
                    <div>
                      <a class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#change_password">{{ $t('user.home.btnChangePsw') }}</a>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <h4>{{ $t('user.home.titValidate') }}</h4>
                <div class="row align-items-top justify-content-start">
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="name_user" class="form-label">{{ $t('user.home.labelName') }}</label>
                    <input type="text" class="form-control" id="name_user" v-model="userForm.name" />
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="name_user" class="form-label">{{ $t('user.home.labelSurname') }}</label>
                    <input type="text" class="form-control" id="surname_user" v-model="userForm.surname" />
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="nick_user" class="form-label">{{ $t('user.home.labelNick') }}</label>
                    <input type="text" class="form-control" id="nick_user" v-model="userForm.nick" @change="testNick(userForm.nick)" />
                    <div class="error_box">
                      <div class="error" v-if="touchedNick && validatedNick == false">This nick is already in use</div>
                      <div class="error" v-if="touchedNick && !$v.userForm.nick.required">This field is required</div>
                      <div class="error" v-if="touchedNick && !$v.userForm.nick.minLength">It's too short</div>
                      <div class="error" v-if="touchedNick && !$v.userForm.nick.nospace">No spaces</div>
                    </div>
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="position_user" class="form-label">{{ $t('user.home.labelOccupation') }}</label>
                    <input type="text" class="form-control" id="position_user" v-model="userForm.position" />
                  </div>
                </div>
                <div class="separator"></div>
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-5" :disabled="submitUserDisabled">{{ $t('user.home.btnConfirm') }}</button>
                  <button type="button" class="btn btn-secondary" @click="getUrl()" :disabled="submitUserDisabled">{{ $t('user.home.btnCancel') }}</button>
                </div>
              </div>
            </form>

            <!-- END User form -->

            <CompanyHelp></CompanyHelp>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyHelp from './UserCompany.vue';

import axios from 'axios';
import password from './modals/Password.vue';

import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";

const lowchar = helpers.regex('low', /^[a-z0-9_.-]*$/);
const nospace = helpers.regex('nospace', /^\S*$/);

export default {
  name: "UserHome",
  components:{
    password,
    CompanyHelp
  },
  validations: {
    
    verifyAccount: {
      email: {
        required,
        minLength: minLength(5),
        email
      }
    },
    userForm: {
      email: {
        required,
        minLength: minLength(5),
        email
      },
      nick: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(30),
        nospace,
        lowchar
      }
    },
  },
  data(){
    return {
      url: '',
      submitUserDisabled: true,
      submitCompanyDisabled: true,
      submitKeywordsDisabled: true,
      submitPasswordDisabled: true,
      
      submittedUserData: false,
      submittedCompanyData: false,
      submittedPassword: false,
      submittedKeywords: false,
      
      submittedResendEmailValidation: false,
      submittedResendEmailOwnVal: false,

      validatedNick: false,
      touchedNick: false,

      touchedLink: false,
      validatedLink: true,

      touchedEmail: false,
      validatedEmail: true,

      domain: '',

      userForm: {
        id: '',
        nick: '',
        name: '',
        surname: '',
        email: '',
        position: '',
        emailVal: ''
      },
    }
  },
  created: function() {

    this.url = this.$route.path;
    let user = this.$route.params.user;

    if(this.$session.exists() && user == this.$session.get('nick')){

      this.userForm.id = this.$session.get('id');

      // get user data
      axios.post('https://shopsuey.net/code/simply/get_user_data.php',{
        id: this.userForm.id,
      })
      .then((response) => {

        if(response.data != 'ko'){
          let allData = response.data;

          this.userForm.name = allData.name;
          this.userForm.surname = allData.surname;
          this.userForm.nick = allData.nick;
          this.userForm.email = allData.email;
          this.userForm.position = allData.position;
          this.userForm.emailVal = parseInt(allData.email_val);
        }

      })
      .then(()=>{
        if(this.userForm.emailVal == 0){
          let showTopValidation = document.getElementById('top_validation');
          showTopValidation.classList.remove('d-none');
        }
      })
      .catch(function(error){
        console.log(error);
      });

    }else{
      this.$router.push('/login');
    }
    
  },

  methods: {

    formChanges(a){
      if(a === 'keywords'){
        this.submitKeywordsDisabled = false;
      }else if(a === 'company'){
        this.submitCompanyDisabled = false;
      }else if(a === 'password'){
        this.submitPasswordDisabled = false;
      }else if(a === 'user'){
        this.submitUserDisabled = false;
      }
      
    },
    sendVerified(){

      if(this.$session.exists()){

        axios.post('https://shopsuey.net/code/simply/send_email_validator.php',{
          iduser: this.$session.get('id'),
        })
        .then((response) => {
          if(response.data == 'ok'){
            this.submittedResendEmailValidation = true;
          }else{
            console.log(response.data); 
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }
    },
    updateUserData(){

      this.submittedUserData = true;

      this.$v.userForm.$touch();

      if(this.$v.userForm.$invalid == false){

        axios.post('https://shopsuey.net/code/simply/update_user_data.php',{
          id: this.$session.get('id'),
          nick: this.$v.userForm.$model.nick,
          name: this.$v.userForm.$model.name,
          surname: this.$v.userForm.$model.surname,
          email: this.$v.userForm.$model.email,
          position: this.$v.userForm.$model.position,
          
        })
        .then((response) => {
          
          if(response.data == 'ok'){
            this.submittedUserData = false;
            this.submitUserDisabled = true;
          }
        })
        .then(() => {
          
          if(this.$v.userForm.$model.nick != this.$session.get('nick') ){
            this.$session.set('nick', this.$v.userForm.$model.nick);
            this.$router.push('/user/' + this.$session.get('nick'));
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }

    },

    testNick(a){
      
      let newNick = a;
      let sessionNick = this.$session.get('nick');
      this.touchedNick = true;
      
      if(this.$session.exists() && newNick != sessionNick){

        axios.post('https://shopsuey.net/code/simply/test_nick.php',{
          nick: newNick
        })
        .then((response) => {
          
          if(response.data == 'ok'){
            this.validatedNick = true;
            this.touchedNick = true;
          }else{
            this.validatedNick = false;
            this.touchedNick = true;
          }
        })
        .catch(function(error){
          console.log(error);
        });
      }else if(this.$session.exists() && newNick == sessionNick){
        this.validatedNick = true;
        this.touchedNick = true;
      }
    },
    testEmail(a){

      this.companyForm.email = a;
      if(this.$v.companyForm.email.email == true){
        this.validatedEmail = true;
        this.touchedEmail = true;
      }else{
        this.validatedEmail = false;
        this.touchedEmail = true;
      }
      
    },
    getUrl(){
      this.$router.go();
    },
    
    
  }
};


</script>