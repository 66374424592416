<template>
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center" id="topmenu" >
      <div class="col-4 col-sm-3 order-1 order-sm-1">
        <h1 class="text-start">
          <router-link to="/" class="nav-link">
            <img src="../assets/img/shopsuey_logo.png" class="img-fluid" style="max-height: 50px;" alt="Logo ShopSuey" />
          </router-link>
        </h1>
      </div>
      <div class="col-12 col-sm-6 order-3 order-sm-2 mb-3 mb-sm-0" v-if="showForm == true">
        <form @submit.prevent="shopsearch(searchInput)">
          <div class="input-group">
            <input type="text" id="searchInput" class="form-control rounded-pill searcher_input text-center" v-model="searchInput" autocomplete="off" />
            <i class="fas fa-search icon_after searcher_button" @click="shopsearch(searchInput)"></i>
          </div>
        </form>
      </div>
      <div class="col-8 col-sm-3 order-2 order-sm-3">
        <div class="text-end" v-if="sessionExist == false">
          <router-link to="/" class="btn btn-secondary btn-sm me-1"><i class="fas fa-home"></i></router-link>
          <router-link to="/shopsuey_companies" class="btn btn-secondary btn-sm me-1">{{ $t('businesInfo') }}</router-link>
          <router-link to="/login" class="btn btn-primary btn-sm ">{{ $t('entry') }}</router-link>
        </div>
        <div class="text-end" v-else >
          <div class="d-inline-block border-end me-1" v-if="showCompany == true">
            <router-link :to="'/user/' + this.$session.get('nick') + '/company/' + storeNick" class="btn btn-primary btn-sm me-1" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" :data-bs-title="storeName"><i class="fas fa-id-badge"></i></router-link>
          </div>
          <router-link to="/" class="btn btn-primary btn-sm me-1"><i class="fas fa-home"></i></router-link>
          <router-link :to="'/user/' + this.$session.get('nick') + '/links'" class="btn btn-primary btn-sm me-1"><i class="fas fa-paperclip"></i></router-link>
          <router-link :to="'/user/' + this.$session.get('nick')" class="btn btn-primary btn-sm me-1"><i class="fas fa-user-circle"></i></router-link>
          <button class="btn btn-primary btn-sm" @click="close()" data-bs-toggle="tooltip" data-bs-placement="top"
        data-bs-custom-class="close session"><i class="fas fa-times-circle"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "HeaderHome",
  validations: {
    searchInput: {
      required,
      minLength: minLength(2),
    }
  },
  data() {
    return {
      sessionExist: false,
      nick_session: '',
      showCompany: false,
      company: [],
      companyLenght: 0,
      searchInput: '',
      showForm: true,
      storeId: 0,
      storeNick: '',
      storeName: '',
    }
  },

  beforeMount: function() {
    if(this.$session.exists() === true){
      this.sessionExist = true;
      this.showCompany = this.$session.get('store_rel');
      this.nick_session = this.$session.get('nick');

      if(this.showCompany == true) {
        this.storeId = this.$session.get('id_store');
        // get company 
        axios.post('https://shopsuey.net/code/simply/get_company_data.php',{
          id: this.storeId,
        })
        .then((response) => {
          // console.log(response);
          this.storeNick = response.data.nick;
          this.storeName = response.data.name;
        })
        .catch(function(error){
          console.log(error);
        });
      }

    }
    this.searchData = this.$route.params.id;
    this.searchInput = this.$route.params.id;
    
  },

  mounted: function(){
    this.routepathsplit = this.$router.currentRoute.path.split('/');
    this.routepath = this.routepathsplit[1];

    if(this.routepath === ''){
      this.showForm = true;
    }

  },
  methods: {
    shopsearch(a){

      this.submitted = true;

      this.$v.$touch();

      let dataSearch = encodeURIComponent(a);

      if(this.$v.$invalid){
        console.log(this.$v);
        return false;
      }else{
        this.$router.push('/search/' + dataSearch);
        this.$router.go('/search/'+ dataSearch);
      }

    },
    goToLink(a){
      var url = a;
      window.open(url, '_blank').focus();
    },
    close(){
      this.$session.destroy();
      this.$router.go('/');
    }
  }

};
</script>