<template>
  <div id="app">
    <HeaderHome :key="rerender"></HeaderHome>
    <div class="container-fluid" id="big-container">
      <div class="row">
        <router-view></router-view>
      </div>
    </div>
    <Footertemplate></Footertemplate>
  </div>
</template>

<script>
import Footertemplate from "./components/Footer.vue";
import HeaderHome from "./components/HeaderHome.vue";

export default {
  name: "App",

  components: {
    Footertemplate,
    HeaderHome
  },
  data(){
    return {
      routepath: '',
      routepathShowLog: false,
      totalShops: 5,
      rerender: 0,
      logo: require('./assets/img/home_top.png'),
      favicon: require('./assets/img/favico.png'),
    }
  },
  metaInfo() {
    return {
      title: 'This is ShopSuey',
      htmlAttrs: {
        lang: 'en',
        amp: true
      },
      link: [
          { rel: 'icon', href: this.favicon }
        ],
      meta: [
          {vmid: "description",name: "description",content:"Discover high quality online shops with awesome products and create your account to never lose them. And if you are the owner of an online business, it is already taking time to upload your store to ShopSuey."},
          // Twitter Card
          {name: 'twitter:card', content: 'summary_large_image'},
          {name: 'twitter:url', content: 'https://shopsuey.net/'},
          {name: 'twitter:title', content: 'This is ShopSuey'},
          {name: 'twitter:description', content: 'Discover awesome online shops and never lose them.'},
          {name: 'twitter:image', content: this.logo},
          // Facebook OpenGraph
          {property: 'og:title', content: 'This is ShopSuey'},
          {property: 'og:url', content: 'https://shopsuey.net/'},
          {property: 'og:site_name', content: 'ShopSuey'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content:  this.logo},
          {property: 'og:description', content: 'Discover awesome online shops and never lose them.'}
      ]
    }
  },

  mounted: function() {
    
  },
  updated: function(){
    window.scrollTo( 0, 0 );
  },
  beforeUpdate: function() {
    this.forceRerender();
  },
  
  methods: {
    forceRerender() {
      this.rerender += 1;
    }
  }
};
</script>

