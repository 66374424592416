<template>
    <!-- Modal upload link -->
    <div class="modal" id="upload_link_company" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" >
          <div class="modal-header">
            <h2>{{ $t('modal.uploadlink.title') }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div id="upload_link_company_div">
              <div class="col-12 p-2">
                <p>{{ $t('modal.uploadlink.txtDescription') }}</p>
              </div>
              
              <form @submit.prevent="uploadLink()">
                <div class="col-12 p-4 mb-3 form_box_gray_soft">
                  <div class="row align-items-top justify-content-center">
                    <div class="mb-2 col-sm-6 col-12">
                      <label for="name_shop" class="form-label">{{ $t('form.labelNameShop') }}</label>
                      <input type="text" class="form-control" id="name_shop" v-model="linkForm.name" />
                    </div>
                    <div class="mb-2 col-sm-6 col-12">
                      <label for="link_shop_upload" class="form-label">{{ $t('form.labelLink') }}</label>
                      <input type="text" class="form-control" id="link_shop_upload" v-model="linkForm.link" @change="testLink(linkForm.link)" />
                      <div class="error_box">
                        <div class="error" v-if="touchedLink && !$v.linkForm.link.required">{{ $t('form.errorRequired') }}</div>
                        <div class="error" v-if="touchedLink && !$v.linkForm.link.minLength">{{ $t('form.errorSize') }}</div>
                        <div class="error" v-if="touchedLink && !$v.linkForm.link.url">{{ $t('form.errorNotLink') }}</div>
                        <div class="error" v-if="touchedLink && validatedLink == false">{{ $t('form.errorLinkInShopsuey') }}</div>
                      </div>

                      
                    </div>
                    <div class=" col-sm-12 col-12">
                      <label for="keywords" class="form-label">{{ $t('form.labelKeywords') }} - <span class="small">{{ $t('form.labelKeywordsSmall') }}</span></label>
                      <div class="input-group mb-3">
                        <input-tag id="keywords" v-model="linkForm.keywords" :limit="10" class="form-control"></input-tag>
                      </div>  
                    </div>
                    <div class="col-12 text-center">
                      <button type="button" class="btn btn-primary" @click="uploadLink()">{{ $t('form.buttonUploadLink') }}</button>
                    </div>
                    
                  </div>
                </div>
              </form>
            </div>
            <div id="upload_link_company_div_ok" class="d-none">
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-top justify-content-center">
                  <div class="col-12 text-center">
                    <h3>{{ $t('modal.uploadlink.thanks') }}</h3>
                    <h4>{{ $t('modal.uploadlink.txtThanks') }}</h4>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >{{ $t('common.buttonCloseWindow') }}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>

    <!-- End Modal -->
</template>

<script>

import axios from 'axios';
import { required, minLength, url } from "vuelidate/lib/validators";

export default {
  name: "UserHome",
  components:{
    
  },
  validations: {
    linkForm: {
      name: {
        required,
        minLength: minLength(2),
      },
      link: {
        required,
        minLength: minLength(2),
        url
      },
      keywords: {
        required
      },
    },
  },
  data(){
    return {
      url: '',
      
      submittedLink: false,
      
      touchedLink: false,
      validatedLink: true,

      linkForm: {
        name: '',
        link: '',
        keywords: [],
      },
      
    }
  },
  beforeMount: function() {

  },
  methods: {

    testLink(a){

      let pathArray = a.split( '/' );
      let protocol = pathArray[0];
      
      // test and build link
      if(protocol != 'https:'){
        let domain = 'https://' + protocol;
        this.linkForm.link = domain;
      }else{
        let host = pathArray[2];
        let url = protocol + '//' + host;
        this.linkForm.link = url;        
      }

      axios.post('https://shopsuey.net/code/testlink.php',{
        link: this.linkForm.link
      })
      .then((response) => {
        
        if(response.data == 0){
          this.validatedLink = true;
        }else if(response.data != 0){
          this.validatedLink = false;
        }
      })
      .then(() => {
        this.touchedLink = true;
      })
      .catch(function(error){
        console.log(error);
      });

    },

    uploadLink(){
      this.submittedLink = true;
      this.$v.linkForm.$touch();

      if(this.$v.linkForm.$invalid == false){

        let id = this.$session.get('id');
        let name = this.$v.linkForm.$model.name;
        let link = this.$v.linkForm.$model.link;
        let keywords = this.$v.linkForm.$model.keywords.toString();

        axios.post('https://shopsuey.net/code/simply/upload_link_company.php',{
          id: id,
          name: name,
          link: link,
          keywords: keywords,
        })
        .then((response) => {
          
          if(response.data == 'ok'){
            const divKo = document.getElementById('upload_link_company_div');
            const divOk = document.getElementById('upload_link_company_div_ok');
            divKo.classList.add('d-none');
            divOk.classList.remove('d-none');
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }
    },
  }
};


</script>