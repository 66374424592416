<template>
  <div class="container-fluid" id="big-container-content">
    <div id="loader" class="d-none" style="background: #ffffffb5; width: 100%; height: 100%; text-align: center;position: absolute;z-index: 10000;">
      <div class="spinner-border" role="status" style="position: relative; top: 45%;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="container">
  
      <div class="row align-items-top justify-content-center">
        
        <div class="col-12 mt-3" id="loginform_form">
          <div class="row align-items-top justify-content-center">

            <div class="col-sm-12 col-md-8">
              <div class="box_black p-4">
                <h2 class="text-white">{{ $t('loginTitle') }}</h2>
                <form @submit.prevent="showmod()">
                  <div class="mb-2">
                    <label for="email_login" class="form-label text-white">{{ $t('labelEmail') }}</label>
                    <input type="email" class="form-control" id="email_login" v-model="loginform.email" />
                    <div class="error_box">
                      <div class="error" v-if="submitted && !$v.loginform.email.required">Field required</div>
                      <div class="error" v-if="submitted && !$v.loginform.email.minLength">It's too short</div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label for="password_verificaton" class="form-label text-white">{{ $t('labelPsw') }}</label>
                    <input type="password" class="form-control" id="password_verificaton" v-model="loginform.password" />
                    <div class="error_box">
                      <div class="error" v-if="submitted && !$v.loginform.password.required">Field required</div>
                      <div class="error" v-if="submitted && !$v.loginform.password.minLength">It's too short</div>
                    </div>
                  </div>
                  <div class="mt-4 text-center">
                    <div class="bg-danger text-white rounded p-2 mb-3 d-none text-center" id="error_login">Password and username do not match. Try again.</div>
                    <button type="submit" class="btn btn-primary btn-lg">{{ $t('buttonLogin') }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="row align-items-center justify-content-center mt-4">
            <div class="col-12 col-md-4">
              <div class="box_white p-2">
                <p class="text-center mb-1">{{ $t('loginForget') }}</p>
                <p class="text-center mb-0">
                  <router-link to="/recovery" class="btn btn-secondary btn-sm">{{ $t('buttonRecover') }}</router-link>
                </p>
              </div>      
            </div>
            <div class="col-12 col-md-4">
              <div class="box_white p-2">
                <p class="text-center mb-1">{{ $t('loginYet') }}</p>
                <p class="text-center mb-0">
                  <router-link to="/signup" class="btn btn-secondary btn-sm">{{ $t('buttonSignup') }}</router-link>
                </p>
              </div>
            </div>
          </div>
          
        </div>

      </div>
     
    </div>

  </div>
  
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios';
import md5 from 'md5';

export default {
  name: "LoginTemplate",
  components: {
    
  },
  
  validations: {
    loginform: {
      email: {
        required,
        minLength: minLength(5),
        email
      },
      password: {
        required,
        minLength: minLength(2)
      }
    }
  },
  data(){
    return {
      submitted: false,
      loginform: {
        email: '',
        password: ''
      }
    }
  },
  beforeMount: function() {

    if(this.$session.exists() === true){
      let nick_session = this.$session.get('nick');
      this.$router.push('/user/' + nick_session);
    }

  },
  mounted: function() {
    document.getElementById('email_login').focus();
  },
  methods: {
    showmod(){

      this.submitted = true;

      this.$v.$touch();

      if(this.$v.$invalid){
        return false;
      }

      let passwordok = md5(this.$v.loginform.$model.password);

      axios.post('https://shopsuey.net/code/simply/login.php',{
        email: this.$v.loginform.$model.email,
        password: passwordok,
      })
      .then((response)=>{

        if(response.data != 'ko'){

          let sessionData = response.data;
          
          this.$session.start();
          this.$session.set('id', sessionData.id);
          this.$session.set('nick', sessionData.nick);
          this.$session.set('name', sessionData.name);
          this.$session.set('surname', sessionData.surname);
          this.$session.set('email', sessionData.email);
          this.$session.set('store_rel', sessionData.store_rel);
          if(sessionData.store_rel == true) {
            this.$session.set('id_store', sessionData.id_store);
          }

          this.$router.push('/user/' + sessionData.nick);

        }else{
          var error_div = document.getElementById('error_login');
          error_div.classList.remove('d-none');
          console.log(response);
          return false
        }
        
      })
      .catch(function(error){
        console.log(error);
      });

    }
  }
};


</script>