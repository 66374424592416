<template>
  <div class="container-fluid bk_black" id="footer">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-6 col-sm-3">
          <ul class="list-unstyled">
            <li><router-link to="/" class="fs-2 text-white link">{{ $t('footerHome') }}</router-link></li>
            <li><router-link to="/project" class="fs-2 text-white link">{{ $t('footerProject') }}</router-link></li>
            <li><router-link to="/shopsuey_companies" class="fs-2 text-white link">{{ $t('footerBusiness') }}</router-link></li>
            <li><router-link to="/contact" class="fs-2 text-white link">{{ $t('footerContact') }}</router-link></li>
            <li><router-link to="/login" class="fs-2 text-white link">{{ $t('footerLogin') }}</router-link></li>
            <li><router-link to="/signup" class="fs-2 text-white link">{{ $t('footerSignup') }}</router-link></li>
          </ul>
        </div>
        <div class="col-6 col-sm-6 text-center">
          <div class="row align-items-center justify-content-center">
            <div class="col-12">
              <ul class="list-unstyled">
                <li><router-link to="/shop" class="fs-2 text-white link">{{ $t('footerMeet') }}</router-link></li>
              </ul>
            </div>
            <div class="col-12 text-center">
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item"><a href="https://www.instagram.com/shopsueynet/" class="fs-2 text-white link" target="_blank"><i class="fab fa-instagram"></i></a></li>
                <li class="list-inline-item"><a href="https://twitter.com/ShopSueyNet" class="fs-2 text-white link" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li class="list-inline-item"><router-link to="/contact" class="fs-2 text-white link" ><i class="far fa-envelope"></i></router-link></li>
              </ul>
              <span class="badge rounded-pill bg-light text-dark fs-6">{{totalShops}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-3 g-0">
          <img src="../assets/img/logo_shop_suey_footer.png" class="img-fluid" alt="Logo ShopSuey" />
        </div>
        <div class="col-12 text-center">
          <div class="row align-items-center justify-content-between">
            
            <div class="col-0 col-sm-4"></div>
            <div class="col-6 col-sm-4 text-start text-sm-center">
              <p>ShopSuey {{year}}</p>
            </div>
            <div class="col-6 col-sm-4 text-center text-sm-end">
              <p class="small"><router-link to="/legal" class="text-white link">{{ $t('footerTerms') }}</router-link> | <router-link to="/cookies" class="text-white link">{{ $t('footerCookies') }}</router-link> | <router-link to="/privacy" class="text-white link">{{ $t('footerPrivacy') }}</router-link></p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "FooterTemplate",
  
  data(){
    return {
      year: new Date().getFullYear(),
      totalShops: '',
    }
  },
  mounted: function() {

    if(this.$session.exists() && this.$session.get('id') == 17){
      axios.get('https://shopsuey.net/code/gettotalshops.php')
      .then((response) => {
        this.totalShops = response.data.message;
      })
      .catch(function(error){
        console.log(error);
      });
    }
  
  }
//   props: {
//     msg: String,
//   },
};
</script>
