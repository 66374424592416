import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'

import vueCountryRegionSelect from 'vue-country-region-select'
import InputTag from 'vue-input-tag'
import Md5 from 'md5'
import VueSession from 'vue-session'
import VuePaginate from 'vue-paginate'
import VueAutosuggest from 'vue-autosuggest'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'

import VueI18n from 'vue-i18n'

import './assets/fontawesome/css/all.css';

import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// import Bootstrap from 'bootstrap';

import './assets/css/styles.css';

import AddLink from './components/AddLink.vue';
import Contact from './components/Contact.vue';
import Home from './components/Home.vue';
import Search from './components/Search.vue';
import About from './components/About.vue';
import ShopHome from './components/ShopHome.vue';
import ProjectHome from './components/ProjectHome.vue';
import ProjectCompanies from './components/ProjectCompanies.vue';
import HeaderHome from './components/HeaderHome.vue';
import UserHome from './components/UserHome.vue';
import UserCompany from './components/UserCompany.vue';
import UserCompanyProfile from './components/UserCompanyProfile.vue';
import UserLinks from './components/UserLinks.vue';
import LegalPage from './components/Legal pages/Legal.vue';
import CookiesPage from './components/Legal pages/Cookies.vue';
import PrivacyPage from './components/Legal pages/Privacy.vue';
// import OwnerHome from './components/OwnerHome.vue';
// import ShopUserStats from './components/ShopUserStats.vue';
// import ShopUserSubscription from './components/ShopUserSubscription.vue';
import Login from './components/Login.vue';
import Recovery from './components/Recovery.vue';
import Recover from './components/Recover.vue';
import Regin from './components/Regin.vue';
import Validation from './components/Validation.vue';
import ValidationOwn from './components/ValidationOwn.vue';

import { i18n } from '@/plugins/i18n'


Vue.config.productionTip = false

Vue.component('HeaderHome', HeaderHome);

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueI18n);
// Vue.use(Bootstrap);
// Vue.use(IconsPlugin);
Vue.use(vueCountryRegionSelect);
Vue.component('input-tag', InputTag);
Vue.use(Md5);
Vue.use(VueSession);
Vue.use(VuePaginate);
Vue.use(VueAutosuggest);
Vue.use(VueGtag, { config: { id: "G-YZQY3WZ6PS" }});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.config.productionTip = false


const routes = [
    { path: '/search/:id?', component: Search },
    { path: '/contact', component: Contact },
    { path: '/about', component: About },
    { path: '/shop', component: ShopHome, name: 'ShopHome'},
    { path: '/project', component: ProjectHome, name: 'ProjectHome'},
    { path: '/shopsuey_companies', component: ProjectCompanies, name: 'ProjectCompanies'},
    { path: '/admin', component: Login },
    { path: '/legal', component: LegalPage },
    { path: '/cookies', component: CookiesPage },
    { path: '/privacy', component: PrivacyPage },
    
    // { path: '/shop/:user?/home', component: OwnerHome },
    // { path: '/shop/:user?/stats', component: ShopUserStats },
    // { path: '/shop/:user?/subscription', component: ShopUserSubscription },

    { path: '/user/:user?/company', component: UserCompany },
    { path: '/user/:user?/company/:company?', component: UserCompanyProfile },
    { path: '/user/:user?/links', component: UserLinks },
    { path: '/user/:user?', component: UserHome },
    { path: '/user', component: Home },

    { path: '/login', component: Login, name: 'Login' },
    { path: '/recovery', component: Recovery },
    { path: '/recover/:cd?', component: Recover },
    { path: '/signup', component: Regin },
    { path: '/add', component: AddLink },
    { path: '/validation/:cd?', component: Validation },
    { path: '/validationown/:cd?', component: ValidationOwn },
    { path: '/', component: Home },
]

const router = new VueRouter({
    routes,
    mode: 'history',
});

new Vue({
  
  router,
  i18n,
  
  render: h => h(App),
}).$mount('#app')
