<template>
  <div class="col-12" id="big-container-content">
    
    <div class="container">

      <div class="row align-items-center justify-content-center mt-3">
        <div class="col-12">
          <div class="row align-items-center justify-content-center mt-3">
            <div class="col-8 col-sm-8">
              <h1>{{ $t('discover.header') }}</h1>
              <h2>{{ $t('discover.subheader') }}</h2>
              
            </div>
            <div class="col-4 col-sm-4">
              <img src="../assets/img/ico_search_shop.png" class="img-fluid" alt="Search Shop Icon" />
            </div>
          </div>  
        </div>
      </div>

      <div class="spacer"></div>

      <div class="row align-items-top justify-content-center">
        <div class="col-sm-4 text-center">
          <i class="fas fa-store pb-5" style="font-size: 4em;"></i>
          <h3>{{ $t('discover.titTrio1') }}</h3>
          <p class="p-2">{{ $t('discover.txtTrio1') }}</p>
        </div>
        <div class="col-sm-4 text-center">
          <i class="fas fa-paperclip pb-5" style="font-size: 4em;"></i>
          <h3>{{ $t('discover.titTrio2') }}</h3>
          <p class="p-2">{{ $t('discover.txtTrio2') }}</p>
        </div>
        <div class="col-sm-4 text-center">
          <i class="fas fa-clipboard-check pb-5" style="font-size: 4em;"></i>
          <h3>{{ $t('discover.titTrio3') }}</h3>
          <p class="p-2">{{ $t('discover.txtTrio3') }}</p>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="row align-items-center justify-content-center mt-3">
        <div class="col-sm-4">
          <img src="../assets/img/logo_shop_suey.png" class="img-fluid" alt="Logo ShopSuey" />
        </div>
        <div class="col-sm-6">
          <h1 class="fs-1">{{ $t('discover.titWhats') }}</h1>
          <h2>{{ $t('discover.subtitWhats') }}</h2>
          <p>{{ $t('discover.txtWhats1') }}</p>
          <p>{{ $t('discover.txtWhats2') }}</p>
        </div>
      </div>

      <div class="spacer"></div>
      
      <div class="row align-items-center justify-content-center mt-2">
        <div class="col-12 col-md-12 mt-3">
          <div class="d-md-flex align-items-stretch text-center">
            <div class="col-6 col-md-6 d-inline-block p-2">
              <div class="box_white_presentation p-5 h-100">
                <h2>{{ $t('discover.callProjectPage') }}</h2>
                <a href="/project" class="btn btn-primary btn-lg">{{ $t('discover.btnProjectPage') }}</a>
              </div>
            </div>

            <div class="col-6 col-md-6 d-inline-block p-2">
              <div class="box_white_presentation p-5 h-100">
                <h2>{{ $t('discover.callBusinessPage') }}</h2>
                <a href="/shopsuey_companies" class="btn btn-primary btn-lg">{{ $t('discover.btnBusinessPage') }}</a>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="row align-items-top justify-content-center">
        <div class="col-12">
          <h2>{{ $t('discover.titSummary') }}</h2>
        </div>
        <div class="col-12 col-sm-6 mt-3 p-2">
          <h3>{{ $t('discover.titTool') }}</h3>
          <p>{{ $t('discover.txtTool') }}</p>
        </div>
        <div class="col-12 col-sm-6 mt-3 p-2">
          <h3>{{ $t('discover.titDiffusion') }}</h3>
          <p>{{ $t('discover.txtDiffusion') }}</p>
        </div>
        <div class="col-12 col-sm-6 mt-3 p-2">
          <h3>{{ $t('discover.titProducts') }}</h3>
          <p>{{ $t('discover.txtProducts') }}</p>
        </div>
        <div class="col-12 col-sm-6 mt-3 p-2">
          <h3>{{ $t('discover.titValidated') }}</h3>
          <p>{{ $t('discover.txtValidated1') }}</p>
          <p>{{ $t('discover.txtValidated2') }}</p>
          <p>{{ $t('discover.txtValidated3') }}</p>
        </div>
        
      </div>

      <!-- <div class="row align-items-top justify-content-center">
        <div class="col-12 col-sm-12 mt-5 box_white_presentation p-3">
          <div class="row align-items-center justify-content-center">
            <div class="col-2 text-center">
              <i class="far fa-newspaper" style="font-size: 4em;"></i>
            </div>
            <div class="col-10">
              <h2 class="mb-3">{{ $t('business.titBreak') }}</h2>
              <p>{{ $t('business.txtBreak1') }}</p>
              <p>{{ $t('business.txtBreak2') }}</p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="spacer"></div>
      

    </div>
  </div>
</template>
<script>
export default {
  name: "ShopHome",
  mounted: function(){
    
  },
  methods: {
    
  }

};

</script>
