<template>
  <div class="col-12">
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-3">
        <img src="../assets/img/ico_search_shop.png" class="img-fluid" />
      </div>
      <div class="col-sm-7">
        <div class="row align-items-center justify-content-center">

          <div class="col-10" >
            <div class="row align-items-center justify-content-center">

              <div class="col-12">
                <h2 class="mt-3">Create a new password</h2>
                <div style="background-color: #f2f2f2; border-radius: 8px" class="col-12 p-4" id="recoverpsw_form">
                  <form @submit.prevent="showmod()">
                    <div class="mb-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="password" class="form-label">Password</label>
                          <input type="password" class="form-control" id="password" v-model="pswForm.password" />
                          <div class="error_box">
                            <div class="error" v-if="submitted && !$v.pswForm.password.required">This field is required</div>
                            <div class="error" v-if="submitted && !$v.pswForm.password.minLength">It's too short</div>
                          </div>
                        </div>
                        <div class="col-12">
                          <label for="repeatPassword" class="form-label">Repeat password</label>
                          <input type="password" class="form-control" id="repeatPassword" v-model="pswForm.repeatPassword" />
                          <div class="error_box">
                            <div class="error" v-if="submitted && !$v.pswForm.repeatPassword.required">This field is required</div>
                            <div class="error" v-if="submitted && !$v.pswForm.repeatPassword.minLength">It's too short</div>
                            <div class="error" v-if="submitted && !$v.pswForm.repeatPassword.sameAsPassword">Passwords don't match</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 text-center">
                      <button type="submit" class="btn btn-primary">Send</button>
                    </div>
                  </form>
                </div>

                <div style="background-color: #f2f2f2; border-radius: 8px" class="col-12 p-4 d-none" id="recoverpsw_form_ok">
                  <h4>Everything is ok!</h4>
                  <p>Try to access with your email and new password</p>
                  <router-link to="/login" class="btn btn-primary">Sign in!</router-link>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-center mt-4">
              <div class="col-sm-6 col-12 text-center">
                <p>Don't you know what are you doing here?</p>
                <router-link to="/" class="btn btn-primary btn-sm">Go home!</router-link>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import axios from 'axios';
import md5 from 'md5';

export default {
  name: "RecoverTemplate",
  components: {
    
  },
  validations: {
    pswForm: {
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('password')
      }
    }
  },
  data(){
    return {
      submitted: false,
      code: '',
      passok: false,
      pswForm: {
        password: '',
        repeatPassword: ''
      }
    }
  },
  beforeMount: function() {
    this.code = this.$route.params.cd;
    
    if(!this.code){
      return false
    }

    axios.post('https://shopsuey.net/code/testrecoverpassword.php',{
      code: this.code
    })
    .then((response) => {
      
      if(response.data.message == 'ok'){
        this.passok = true;
      }else if(response.data.message == 'ko'){
        this.passok = false;
        this.$router.push('/')
      }
    })
    .catch(function(error){
      console.log(error);
    });

    
  },
  methods: {
    showmod(){
      
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid){  
        return false;
      }

      let password = md5(this.pswForm.password);
      
      axios.post('https://shopsuey.net/code/changepassword.php',{
        code: this.code,
        psw: password
      })
      .then((response) => {
        
        if(response.data.message == 'ok'){
          var reginForm = document.getElementById('recoverpsw_form');
          reginForm.remove();
          var reginFormOk = document.getElementById('recoverpsw_form_ok');
          reginFormOk.classList.remove('d-none');          
        }else if(response.data.message == 'ko'){
          alert('Sorry! Something goes wrong. Try later.')
        }
      })
      .catch(function(error){
        console.log(error);
      });

    }
  }
};


</script>