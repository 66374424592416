<template>
  <div class="col-12" id="big-container-content">

    <div class="row align-items-center justify-content-center">
      <div class="col-sm-10 col-12 mb-4">
        <div class="row align-items-center justify-content-center">
          <div class="col-12" v-if="companyVisible == true">
            
            <form @submit.prevent="sendCompanyForm()" @change="formChanges('company')">

              <h2 class="mb-3">{{companyForm.name}}</h2>

              <h3>{{ $t('user.company.titProfile') }}</h3>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">

                <h4>{{ $t('user.company.titCompany') }}</h4>
                <div class="row align-items-top justify-content-start">
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="name_shop" class="form-label">{{ $t('user.company.labelCompanyName') }}</label>
                    <input type="text" class="form-control" id="name_shop" v-model="companyForm.name" />
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="nick_shop" class="form-label">{{ $t('user.company.labelCompanyNick') }}</label>
                    <input type="text" class="form-control" id="nick_shop" v-model="companyForm.nick" @change="testCompanyNick(companyForm.nick)" />
                    <div class="error_box">
                      <div class="error" v-if="validatedNick == false && touchedNick == true">This nick is already in ShopSuey database.</div>
                    </div>
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="link_shop" class="form-label">{{ $t('user.company.labelCompanyLink') }}</label>
                    <input type="text" class="form-control" id="link_shop" v-model="companyForm.link" @change="testLink(companyForm.link)" />
                    <div class="error_box">
                      <div class="error" v-if="touchedLink && validatedLink == false">This link already exists</div>
                      <div class="error" v-if="touchedLink && !$v.companyForm.link.required">This field is required</div>
                      <div class="error" v-if="touchedLink && !$v.companyForm.link.minLength">It's too short</div>
                      <div class="error" v-if="touchedLink && !$v.companyForm.link.url">This is not a link</div>
                    </div>
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="name_admin" class="form-label">{{ $t('user.company.labelCompanyEmail') }}</label>
                    <input type="text" class="form-control" id="name_admin" v-model="companyForm.email" @change="testEmail(companyForm.email)" />
                    <div class="error_box">
                      <div class="error" v-if="touchedEmail && !$v.companyForm.email.email">This is not a email</div>
                    </div>
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="country_shop" class="form-label">{{ $t('user.company.labelCountry') }}</label>
                    <country-select id="country_shop" class="form-select" v-model="companyForm.country" :country="companyForm.country" :placeholder="$t('user.company.labelSelectCountry')" :usei18n="false" :countryName="true" />
                  </div>
                  <div class="mb-2 col-sm-6 col-12">
                    <label for="product_shop" class="form-label">{{ $t('user.company.labelProductType') }}</label>
                    <select id="product_shop" class="form-select" v-model="companyForm.product">
                      <option value="0">Select a product type</option>
                      <option value="1">Products</option>
                      <option value="2">Services</option>
                    </select>
                  </div>
                  <div class="mb-2 col-sm-12 col-12">
                    <label for="desc_shop" class="form-label">{{ $t('user.company.labelDescription') }}</label> 
                    <textarea class="form-control" id="desc_shop" v-model="companyForm.description" ></textarea>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-5" :disabled="submitCompanyDisabled">{{ $t('user.company.btnProfileConfirm') }}</button>
                  <button type="button" class="btn btn-secondary" @click="getUrl()" :disabled="submitCompanyDisabled">{{ $t('user.company.btnProfileRemove') }}</button>
                </div>
              </div>  
            </form>

            <form @submit.prevent="sendKeywordsForm()" >
              <h3>{{ $t('user.company.titKeywords') }}</h3>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-top justify-content-center">
                  <div class=" col-sm-12 col-12">
                    <label for="keywords" class="form-label">{{ $t('user.company.labelTxtKeywords') }} <span class="small">{{ $t('user.company.labelTxtKeywords2') }}</span></label>
                    <div class="input-group mb-3">
                      <input-tag id="keywords" v-model="keywordsForm.keywords" :limit="10" @input="formChanges('keywords')" class="form-control"></input-tag>
                    </div>  
                  </div>
                </div>
                <div class="separator"></div>
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-5" :disabled="submitKeywordsDisabled">{{ $t('user.company.btnKeyConfirm') }}</button>
                  <button type="button" class="btn btn-secondary" @click="getUrl()" :disabled="submitKeywordsDisabled">{{ $t('user.company.btnKeyRemove') }}</button>
                </div>
              </div>
              
            </form>

            <!-- <form @submit.prevent="sendRolesForm()" v-if="rolesForm.role != 0" >
              <h3>Roles</h3>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-top justify-content-center">
                  <div class=" col-sm-12 col-12">
                    <h2 class="text-center">{{ rolesForm.role }}</h2>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="mt-2 text-center">
                  <button type="submit" class="btn btn-primary me-5" :disabled="submitRolesDisabled">Confirm Roles changes</button>
                  <button type="button" class="btn btn-secondary" @click="getUrl()" :disabled="submitRolesDisabled">Remove Roles changes</button>
                </div>
              </div>
              
            </form> -->
          </div>


          <div class="col-12" v-else>
            <!-- Verification div -->
            <div class="row"  v-if="companyForm.ownval === false">
              <div class="col-12 text-center">
                <h2><i class="fas fa-ring" style="font-size: 2em;"></i></h2>
                <h4>{{ $t('user.companyVerify.titComplete') }}</h4>
                <p>{{ $t('user.companyVerify.txtComplete') }}</p>
              </div>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 col-sm-8 text-center mb-5" >
                    <h4>{{ $t('user.companyVerify.titDomain') }}</h4>
                    <p class="small">{{ $t('user.companyVerify.txtDomain') }}</p>
                    <div class="code_box_div rounded">
                      <div class="code_box">
                        <code id="metacode">&lt;meta name=&quot;shopsuey-site-verification&quot; content=&quot;{{companyForm.ownvalcode}}&quot;&gt;</code>  
                      </div>
                    </div>
                    <button id="button_copy" class="btn btn-primary mt-2" @click="copyCode()">{{ $t('user.companyVerify.labelCopy') }}</button>
                  </div>
                  <div class="col-12 col-sm-8 text-center" >
                    <h4>{{ $t('user.companyVerify.titCheck') }}</h4>
                    <p>{{ $t('user.companyVerify.txtCheck') }}</p>
                    <button class="btn btn-primary" @click="checkOwner()">{{ $t('user.companyVerify.btnCheck') }}</button>
                  </div>
                </div>
              </div>
              
            </div>
            <!-- END Verification div -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { required, email, url, minLength } from "vuelidate/lib/validators";

export default {
  name: "UserCompanyProfile",
  components:{
    
  },
  validations: {
    verifyAccount: {
      email: {
        required,
        minLength: minLength(5),
        email
      }
    },
    companyForm: {
      name: {
        required,
        minLength: minLength(2)
      },
      nick: {
        required,
        minLength: minLength(2)
      },
      link: {
        required,
        minLength: minLength(2),
        url,
      },
      email: {
        minLength: minLength(5),
        email
      },
    },
    keywordsForm: {
      keywords: {
        required
      },
    }
  },
  data(){
    return {
      company: [],
      url: '',
      nick_session: '',
      companyVisible: false,

      submitCompanyDisabled: true,
      submitKeywordsDisabled: true,
      
      submittedCompanyData: false,
      submittedKeywords: false,
      
      submittedResendEmailValidation: false,
      submittedResendEmailOwnVal: false,

      validatedNick: false,
      touchedNick: false,

      touchedLink: false,
      validatedLink: true,

      touchedEmail: false,
      validatedEmail: true,

      domain: '',

      companyForm: {
        id: '',
        name: '',
        email: '',
        nick: '',
        link: '',
        description: '',
        country: '',
        validated: '',
        product: '',
        ownval: '',
        ownvalcode: '',
        emailval: '',
      },
      keywordsForm: {
        keywords: [],
      },
      rolesForm: {
        role: '',
      },
      rerender: 0,
    }
  },
  
  beforeMount: function() {

    this.url = this.$route.path;
    // let userparam = this.$route.params.user;
    // let companyparam = this.$route.params.company;

    if(this.$session.exists() && this.$session.get('store_rel') == true){

      this.storeId = this.$session.get('id_store');

      // get company data
      axios.post('https://shopsuey.net/code/simply/get_company_data.php',{
        id: this.storeId,
      })
      .then((response) => {

        if(response.data != 'ko') {
          this.company = response.data;
          let key = this.company.keywords.split(',');

          this.companyForm.id = this.company.id_store;
          this.companyForm.name = this.company.name;
          this.companyForm.nick = this.company.nick;
          this.companyForm.link = this.company.link;
          this.companyForm.email = this.company.email;
          this.companyForm.description = this.company.description;
          this.companyForm.country = this.company.country;
          this.companyForm.validated = this.company.validated;
          this.companyForm.product = this.company.product;
          if(this.company.own_val != 0){
            this.companyForm.ownval = true;
          }else{
            this.companyForm.ownval = false;
          }
          
          this.companyForm.emailval = this.company.email_val;

          this.companyForm.ownvalcode = this.company.own_val;
          this.keywordsForm.keywords = key;

          if(this.company.role == 1){
            this.rolesForm.role = 'Administrator';
          }else if(this.company.role == 2){
            this.rolesForm.role = 'Observer';
          }else if(this.company.role == 3){
            this.rolesForm.role = 'Editor';
          }else{
            this.rolesForm.role = 0;
          }

          let link = new URL(this.companyForm.link);
          this.domain = link.hostname;
        }

      })
      .then(() => {
        if(this.companyForm.ownvalcode != 0){
          this.companyVisible = true;
        }else{
          this.companyVisible = false;
        }
      })
      .catch(function(error){
        console.log(error);
      });

    }else{
      this.$router.push('/login');
    }
    
  },
  methods: {
    copyCode(){
      let code = document.getElementById('metacode').textContent;
      let button = document.getElementById('button_copy');
      navigator.clipboard.writeText(code);
      button.innerText = "Copied!";
      setTimeout(()=> {
        button.innerText = 'Copy code!';
      },2000)
    },
    formChanges(a){
      if(a === 'keywords'){
        this.submitKeywordsDisabled = false;
      }else if(a === 'company'){
        this.submitCompanyDisabled = false;
      }
      
    },
    testCompanyNick(a){
      
      let sessionNick = this.companyForm.nick;
      this.touchedNick = true;
      
      if(this.$session.exists() && a != sessionNick){

        axios.post('https://shopsuey.net/code/simply/test_company_nick.php',{
          nick: a,
          idstore: this.companyForm.id,
        })
        .then((response) => {
          
          if(response.data == 'ok'){
            this.validatedNick = true;
            this.touchedNick = false;
          }else{
            this.validatedNick = false;
            this.touchedNick = true;
          }
        })
        .catch(function(error){
          console.log(error);
        });
      }else{
        this.validatedNick = true;
        this.touchedNick = false;
      }
    },
    testLink(a){

      let pathArray = a.split( '/' );
      let protocol = pathArray[0];
      
      if(protocol == 'http:' || protocol == 'https:'){
        let host = pathArray[2];
        let url = protocol + '//' + host;
        let hostParts = host.split( '.' );
        
        if (hostParts.length === 2){
          this.companyForm.link = url;
        }else if(hostParts[1] && hostParts[2]){
          this.companyForm.link = protocol + '//' + hostParts[1] + '.' + hostParts[2];
        }else{
          this.validatedLink = false;
          this.touchedLink = true;
        }

        if(this.validatedLink == true){
          axios.post('https://shopsuey.net/code/simply/test_link.php',{
            link: url,
            idstore: this.companyForm.id,
          })
          .then((response) => {
            if(response.data == 'ok'){
              this.validatedLink = true;
            }else{
              this.validatedLink = false;
            }
          })
          .then(() => {
            this.touchedLink = true;
          })
          .catch(function(error){
            console.log(error);
          });
        }

      }else{
        this.validatedLink = false;
      }
      
    },
    testEmail(a){

      this.companyForm.email = a;
      if(this.$v.companyForm.email.email == true){
        this.validatedEmail = true;
        this.touchedEmail = true;
      }else{
        this.validatedEmail = false;
        this.touchedEmail = true;
      }
      
    },

    checkOwner(){
      axios.post('https://shopsuey.net/code/simply/test_owner.php',{
        id: this.companyForm.id,
        code: this.companyForm.ownvalcode,
      })
      .then((response) => {
        
        if(response.data == 'ok'){
          this.companyForm.ownval = true;
        }else{
          console.log(response.data);
        }

      })
      .catch(function(error){
        console.log(error);
      })

    },
    getUrl(){
      this.$router.go();
    },
    goToUrl(a){
      this.$router.push(a);
    },
    goToBack(){
      this.$router.go(-1);
    },
    sendCompanyForm(){

      this.submittedCompanyData = true;

      this.$v.companyForm.$touch();

      this.testLink(this.$v.companyForm.$model.link);
      this.testCompanyNick(this.$v.companyForm.$model.nick);

      console.log(this.validatedLink, this.validatedNick);

      if(this.$v.companyForm.$invalid == false && this.validatedLink == true && this.validatedNick == true){
        
        axios.post('https://shopsuey.net/code/simply/update_company_data.php',{
          id: this.companyForm.id,
          iduser: this.$session.get('id'),
          name: this.$v.companyForm.$model.name,
          nick: this.$v.companyForm.$model.nick,
          link: this.$v.companyForm.$model.link,
          email: this.$v.companyForm.$model.email,
          country: this.$v.companyForm.$model.country,
          product: this.$v.companyForm.$model.product,
          description: this.$v.companyForm.$model.description,
        })
        .then((response) => {
          if(response.data == 'ok'){
            this.submittedCompanyData = false;
            this.submitCompanyDisabled = true;
          }
        })
        .then(() => {
          let companyparam = this.$route.params.company;
          if(this.$v.companyForm.$model.nick != companyparam ){
            this.$router.push('/user/' + this.$session.get('nick') + '/company/' + this.$v.companyForm.$model.nick);
          }
        })
        .catch(function(error){
          console.log(error);
        })

      }else{
        console.log(this.$v.companyForm);
        return false;
      }

    },
    sendKeywordsForm(){

      this.submittedKeywords = true;

      this.$v.keywordsForm.$touch();
      if(this.$v.keywordsForm.$invalid){
        return false;
      }else{

        let keywordsList = this.$v.keywordsForm.$model.keywords.toString();

        axios.post('https://shopsuey.net/code/simply/update_keywords_data.php',{
          id: this.companyForm.id,
          keywords: keywordsList,
        })
        .then((response) => {
          
          if(response.data == 'ok'){
            this.submittedKeywords = false;
            this.submitKeywordsDisabled = true;
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }

    }

  }
};


</script>