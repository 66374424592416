<template>
  
  <div class="container-fluid" id="big-container-content">
    <div class="container">

      <div class="row align-items-top justify-content-center">
        
        <div class="col-12 mt-3" id="recover_form">
          <div class="row align-items-top justify-content-center">
            <div class="col-sm-12 col-md-8 order-sm-1 order-md-2">
              <div class="box_black p-4">
                <h2 class="text-white">{{ $t('recoverTitle') }}</h2>
                <form @submit.prevent="showmod()">
                  <div class="mb-2">
                    <label for="email_verificaton" class="form-label text-white">{{ $t('labelEmail') }}</label>
                    <input type="email" class="form-control" id="email_verificaton" v-model="recoverForm.email" />
                    <div class="error_box">
                      <div class="error" v-if="submitted && !$v.recoverForm.email.required">Field required</div>
                      <div class="error" v-if="submitted && !$v.recoverForm.email.minLength">It's too short</div>
                    </div>
                  </div>
                  <div class="mt-4 text-center">
                    <button type="submit" class="btn btn-primary">{{ $t('buttonSend') }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          
          <div class="row align-items-center justify-content-center mt-4">
            <div class="col-12 col-md-4">
              <div class="box_white p-2">
                <p class="text-center mb-1">{{ $t('recoverRemember') }}</p>
                <p class="text-center mb-0">
                  <router-link to="/login" class="btn btn-secondary btn-sm">{{ $t('buttonLogin') }}</router-link>
                </p>
              </div>      
            </div>
            <div class="col-12 col-md-4">
              <div class="box_white p-2">
                <p class="text-center mb-1">{{ $t('recoverSignup') }}</p>
                <p class="text-center mb-0">
                  <router-link to="/signup" class="btn btn-secondary btn-sm">{{ $t('buttonSignup') }}</router-link>
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="mt-3 d-none" id="recover_form_ok">
          <div class="row align-items-center justify-content-center pt-4 pb-4">
            <div class="col-sm-12 col-md-8 order-sm-1 order-md-2">
              <div class="box_black p-4 text-center">
                <h2 class="text-white">{{ $t('recoverCheckmail') }}</h2>
                <router-link to="/login" class="btn btn-primary btn-sm">{{ $t('buttonLogin') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "RecoveryTemplate",
  components: {
    
  },
  validations: {
    recoverForm: {
      email: {
        required,
        minLength: minLength(5),
        email
      },
    }
  },
  data(){
    return {
      submitted: false,
      recoverForm: {
        email: ''
      }
    }
  },
  beforeMount: function(){
    if(this.$session.exists() === true){
      let nick_session = this.$session.get('nick');
      this.$router.push('/user/' + nick_session);
    }
  },
  methods: {
    showmod(){
      
      this.submitted = true;

      this.$v.$touch();

      if(this.$v.$invalid){
        return false;
      }

      axios.post('https://shopsuey.net/code/recoverpassword.php',{
        email: this.$v.recoverForm.$model.email
      })
      .then((response) => {
        console.log(response);
        if(response.data.message == 'ok'){
          document.getElementById('recover_form').remove();
          document.getElementById('recover_form_ok').classList.remove('d-none');
        }
      })
      .catch(function(error){
        console.log(error);
      });

    }
  }
};


</script>