<template>
    <!-- Modal owner -->
    <div class="modal" id="owner_link" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" >
          <div class="modal-header">
            <h2>{{ $t('modal.owner.title') }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="user_owner == false">
            <div id="owner_link_div">
              <div class="col-12 p-2">
                <p>{{ $t('modal.owner.textDescription') }}</p>
                <p>{{ $t('modal.owner.textDescription2') }}</p>
              </div>

              <form @submit.prevent="createOwnerProfile()">
                <div class="col-12 p-4 mb-3 form_box_gray_soft">
                  <div class="row align-items-top justify-content-center">
                    <div class="mb-2 col-sm-6 col-12">
                      <label for="link_shop_modal" class="form-label">{{ $t('form.labelCompanyLink') }}</label>
                      <input type="text" class="form-control" id="link_shop_modal" v-model="ownerForm.link" @keypress="testOwnerLink(ownerForm.link)" @change="testOwnerLink(ownerForm.link)" />
                      <div class="error_box">
                        <div class="error" v-if="touchedOwnerLink && caseOwnerLink == 1">{{ $t('form.errorOwnerLink1') }}</div>
                        <div class="error" v-if="touchedOwnerLink && caseOwnerLink == 2">{{ $t('form.errorOwnerLink2') }}</div>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <div v-if="touchedOwnerLink && uploadCompanyLink == false">
                        <button type="button" class="btn btn-primary" @click="createOwnerProfile()" :disabled="createOwnerLink == false">{{ $t('form.buttonCreateOwner') }}</button>
                      </div>
                      
                      <div v-if="touchedOwnerLink && uploadCompanyLink == true">
                        <button type="button" class="btn btn-primary" data-bs-target="#upload_link_company" data-bs-toggle="modal">{{ $t('form.buttonUploadLink') }}</button>
                        <p>{{ $t('form.errorCompanyLink') }}</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </form>

            </div>
            <div id="owner_link_div_ok" class="d-none">
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-top justify-content-center">
                  <div class="col-12 text-center">
                    <h3>{{ $t('modal.owner.titleThanks') }}</h3>
                    <h4>{{ $t('modal.owner.textThanks') }}</h4>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >{{ $t('common.buttonCloseWindow') }}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="modal-body" v-else>
            <div class="">
              <div class="col-12 p-4 mb-3">
                <div class="row align-items-top justify-content-center">
                  <div class="col-12 text-center">
                    <h3>{{ $t('modal.owner.titleOwner') }}</h3>
                    <h4>{{ $t('modal.owner.subtitleOwner') }}<i class="fas fa-id-badge"></i></h4>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >{{ $t('common.buttonCloseWindow') }}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>

    <!-- End Modal -->
</template>

<script>

import axios from 'axios';
import { required, minLength, url } from "vuelidate/lib/validators";

export default {
  name: "OwnerLink",
  components:{
    
  },
  validations: {
    ownerForm: {
      id: {
        required
      },
      link: {
        required,
        minLength: minLength(2),
        url
      }
    },
  },
  data(){
    return {
      url: '',
      
      submittedOwnerLink: false,
      
      touchedOwnerLink: false,
      createOwnerLink: false,
      uploadCompanyLink: false,

      caseOwnerLink: 0,

      user_owner: false,

      ownerForm: {
        id: '',
        link: '',
      },
      
    }
  },
  beforeMount: function() {
    axios.post('https://shopsuey.net/code/test_user_owner.php',{
      id: this.$session.get('id'),
    })
    .then((response) => {
      if(response.data == 'rel'){
        this.user_owner = true;
      }else if(response.data == 'no_rel'){
        this.user_owner = false;
      }
    })
    .catch(function(error){
      console.log(error);
    });
  },
  methods: {

    testOwnerLink(a){

      let pathArray = a.split( '/' );
      let protocol = pathArray[0];
      
      // test and build link
      if(protocol == 'http:' || protocol == 'https:'){
        let domain = 'https://' + pathArray[2];
        this.ownerForm.link = domain;
      }else{
        let domain = 'https://' + pathArray[0];
        this.ownerForm.link = domain;
      }

      if(this.$v.ownerForm.link.$invalid == false){
        axios.post('https://shopsuey.net/code/testownerlink.php',{
          link: this.ownerForm.link,
          id_user: this.$session.get('id'),
        })
        .then((response) => {
          // console.log(response.data);

          this.createOwnerLink = false;
          this.uploadCompanyLink = false;
          this.touchedOwnerLink = false;
          this.caseOwnerLink = 0;

          if(response.data.action == 'ok'){
            this.createOwnerLink = true;
            this.uploadCompanyLink = false;
            this.ownerForm.id = response.data.id_store;
          }else if(response.data.action == 'ko'){
            if(response.data.origin == 'not in db'){
              this.createOwnerLink = false;
              this.uploadCompanyLink = true;
              this.caseOwnerLink = 1;
            }else if(response.data.origin == 'in db & rel'){
              this.createOwnerLink = false;
              this.uploadCompanyLink = false;
              this.caseOwnerLink = 2;
            }
          }

        })
        .then(() => {
          this.touchedOwnerLink = true;
        })
        .catch(function(error){
          console.log(error);
        });
      }
    },

    createOwnerProfile(){
      this.submittedLink = true;
      this.$v.ownerForm.$touch();

      if(this.$v.ownerForm.$invalid == false && this.ownerForm.id){

        let id_user = this.$session.get('id');
        let id_store = this.ownerForm.id;

        axios.post('https://shopsuey.net/code/savecompanyuserrel.php',{
          id_user: id_user,
          id_store: id_store
        })
        .then((response) => {
          console.log(response.data);
          if(response.data == 'ok'){
            const divKo = document.getElementById('owner_link_div');
            const divOk = document.getElementById('owner_link_div_ok');
            divKo.classList.add('d-none');
            divOk.classList.remove('d-none');
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }
    },
  }
};


</script>