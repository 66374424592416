<template>
    <!-- Modal owner -->
    <div class="modal" id="savelink_info" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" >
          <div class="modal-header">
            <h2>{{ $t('modal.savelink.title') }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row align-items-top justify-content-center">
              <div class="col-12 text-start">
                <h4>{{ $t('modal.savelink.subtitle') }}</h4>
                <p>{{ $t('modal.savelink.txtDescription') }}</p>
                <p>{{ $t('modal.savelink.txtClaim') }}</p>
                <p class="text-center"><a href="/signup" class="btn btn-primary">{{ $t('modal.savelink.buttonSignup') }}</a></p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <!-- End Modal -->
</template>

<script>

export default {
  name: "SaveLinkInfo",
  components:{
    
  },

  data(){
    return {
     
    }
  },
  beforeMount: function() {

  },
  methods: {

  }
};


</script>