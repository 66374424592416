import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const messages = {
    
    en: {
        hello: 'Hello World!',
        what: 'What are you looking for?',
        searchMessage: 'Search by product, service, store type,...',
        businessClaim: 'Do you want see your company in ShopSuey?',
        buttonClaim: 'Meet ShopSuey!',
        suggestions: 'Suggestions',
        businesInfo: 'Business info',
        entry: 'Entry',
        footerHome: 'Home',
        footerProject: 'The Project',
        footerBusiness: 'Business',
        footerContact: 'Contact',
        footerLogin: 'Log in',
        footerSignup: 'Sign up',
        footerMeet: 'Meet ShopSuey',
        footerTerms: 'Terms',
        footerCookies: 'Cookies',
        footerPrivacy: 'Privacy',
        login: 'Log in',
        buttonLogin: 'Log in',
        loginTitle: 'Log in',
        textEmail: 'Email',
        labelPsw: 'Password',
        loginForget: 'Forget password?',
        loginYet: "Don't have an account yet?",
        buttonRecover: 'Recover it!',
        buttonSignup: 'Sign up',
        recoverTitle: 'Recover password',
        buttonSend: 'Send',
        recoverRemember: 'Do you remember yet?',
        recoverSignup: "Don't have an account?",
        recoverCheckmail: 'Check your email',
        signupTitle: 'Sign up',
        signupSubtitle: "Create your account. It's free!",
        labelName: 'Name',
        labelEmail: 'Email address',
        labelPswRemember: "Remember: minimum 8 digits (Uppercase, lowercase and numbers)",
        labelCompanyQuestion: "Are you a company? Do you want to upload the link of your store?",
        inputYes: 'Yes',
        inputNo: 'No',
        labelNameShop: 'Name shop',
        labelLink: 'Link',
        labelKeywords: 'Keywords',
        labelKeyMessage: "(use tab or comma to create keyword)",
        buttonCreateAccount: 'Create account',
        titleThanksSignup: 'Thanks for Sign up!',
        msgThanksSignup: 'Now, go to your mailbox and verify your email address account. See you soon!',
        common:{
            buttonCloseWindow: "Close window",
        },
        form: {
            labelLink: "Link",
            labelCompanyLink: "Your company link",
            errorOwnerLink1: "This link don't exist in ShopSuey",
            errorOwnerLink2: "This link has an administrator",
            buttonCreateOwner: "Create owner profile",
            errorCompanyLink: "To create your owner account you will first have to upload the link to ShopSuey.",
            buttonUploadLink: "Upload a link",
            labelCurrentPassword: "Current password",
            labelNewPassword: "New password",
            labelRepeatPassword: "Repeat new password",
            errorMatchPasswords: "Passwords do not match.",
            errorPasswordInvalid: "Current password is invalid. Try again.",
            buttonChangePassword: "Change password",
            labelNameShop: "Name shop",
            errorRequired: "This field is required",
            errorSize: "It's too short",
            errorNotLink: "This is not a link",
            errorLinkInShopsuey: "This link is in ShopSuey",
            labelKeywords: "Keywords",
            labelKeywordsSmall: "(use tab or comma to create keyword)",
            labelTypeContact: "What do you want to talk about?",
            optionTypeContact1: "Let's just talk",
            optionTypeContact2: "Talk about business",
            optionTypeContact3: "I want to report an error",
        },
        modal:{
            conditions: {
                title: 'Conditions',
                textDescription: "To publish a link on ShopSuey you must meet the following conditions:",
                textList1: "The proposed website must offer at least one product or service",
                textList2: ">The website must be commercial in nature",
                textList3: "The link must be a domain, without subfolders or parameters",
                textList4: "The link cannot be a subdomain",
                textList5: "Redirected links will be rejected",
                textList6: "Aggregator websites will not be accepted",
            },
            owner: {
                title: "Owner!",
                textDescription: "If you are the owner of one of the websites added to ShopSuey you can claim ownership and create a company profile.",
                textDescription2: "A company profile allows you to control keywords, manage your information, prepare campaigns, and much more for your business.",
                titleThanks: "Thanks!",
                textThanks: "Our team is validating your link. As soon as we validate it, we will send you an email. Thanks for your help!",
                titleOwner: "You already have a property",
                subtitleOwner: "And you can access it through the top menu -> ",
            },
            password: {
                title: "Change your password",
                remember: "Remember! Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number.",
                thanks: "Congratulations! Your password is correctly changed."

            },
            savelink:{
                title: "Don't forget a store!",
                subtitle: "Save all store links you love",
                txtDescription: "This is one of the many advantages of registering with ShopSuey.",
                txtClaim: "Come on! What are you waiting for?",
                buttonSignup: "Sign up",
            },
            sharelink:{
                title: "Share ShopSuey!",
                subtitle: "Save all store links you love",
                txtDescription: "There are countless unknown stores on the net. If you know any of them, send them our link directly or post it on your social networks and help us grow together.",
                txtClaim: "Copy and paste this link all around the world!",
                web: "ShopSuey.com",
                buttonCopy: "Copy ShopSuey Link!",
            },
            uploadlink:{
                title: "Upload a link company",
                txtDescription: "You can contribute by uploading the link of that store that has you in love. At ShopSuey we review the links that users send us and validate their content. When the link is validated we will notify you by email. That easy!",
                thanks: "Thanks!",
                txtThanks: "Our team is validating your link. As soon as we validate it, we will send you an email. Thanks for your help!",
            },
        },
        regin: {
            business_box: {
                textMessage1: 'Are you a company?',
                textMessage2: "You're in the right way!",
                textRemember: 'Remember!',
                txt1: 'Create personal account',
                txt2: 'Upload your store link',
                txt3: 'Claim ownership',
                textAccount: 'Have you an account?',
            }
        },
        
        project: {
            header: 'The ShopSuey Project',
            titWhat: 'What is ShopSuey?',
            txtWhat: 'ShopSuey is a social business repository.',
            titRepo: 'Social repository?',
            txtRepo1: 'Yes. In ShopSuey it is both users and companies that propose store links.',
            txtRepo2: 'All links are manually reviewed and approved because we look for quality over quantity.',
            titWhy: 'Why ShopSuey?',
            txtWhy1: 'Craftsmen, artists, creators of incredible products. There are countless professions that claim quality over quantity and their voices are lost in search engines in favor of larger companies.',
            txtWhy2: "What we propose at ShopSuey is simply to give companies, large and small, the possibility of defining their companies themselves and deciding which keywords they want to be discovered by. It's about changing the rules to not rely on an algorithm and find a more consistent way to connect potential customers with the right companies.",
            titOffer: "What does ShopSuey offer?",
            txtOffer1: "ShopSuey is a promotional opportunity for companies, a platform to attract potential customers. And at the same time, it is a space for clients to find companies specialized in what they are looking for. Simple.",
            txtOffer2: "We validate all the links that both users and companies publish on ShopSuey because what we are looking for is that the search results are exclusively from companies that offer a specific product or service.",
            titHow: "How does it work?",
            forClients: "For clients",
            forCompanies: "For companies",
            iconSearch: "Search",
            iconSave: "Save",
            iconCollaborate: "Collaborate",
            iconPublish: "Publish",
            iconManage: "Manage",
            iconPromotion: "Promotion",
            txtClients1: "For those users looking for a product to buy, ShopSuey works like any search engine. You enter the name of the product or service you are looking for and ShopSuey offers you a list of companies that are related to the search carried out.",
            txtClients2: "Additionally, if the user wishes, they can save said link in their personal account to consult it whenever they want.",
            txtClients3: "In addition, ShopSuey offers users the possibility of supporting companies that have a website that meets the requirements. In this way and through a simple form, a user sends us their proposal and we review it and if the link meets the requirements we publish the link on ShopSuey.",
            txtCompanies1: "ShopSuey is a repository for companies and all those that have a website can claim ownership or upload their link completely free of charge.",
            txtCompanies2: "All companies have a profile where they can customize everything that ShopSuey shows about them (company name, description, etc.) and, most importantly, control the keywords that relate user searches to their company.",
            txtAdBusiness: "Are you a company? We recommend that you continue reading at the following link:",
            btnAdBusiness: "ShopSuey for business",
            titDatabase: "A social database",
            txtDatabase1: "The list of ShopSuey links is compiled by the members of ShopSuey and all its users. All proposed links are saved and reviewed regardless of who submitted the proposal.",
            txtDatabase2: "Once the filters have been passed, the proposed link is published on ShopSuey and the user is informed of the approval of the link."
        },
        discover: {
            header: 'Discover ShopSuey!',
            subheader: "The social repository of online stores",
            titTrio1: "Discover online stores",
            txtTrio1: "In ShopSuey you will find online stores. You can search by product, brands, services, but you will always find online stores.",
            titTrio2: "Save and organize your favorites",
            txtTrio2: "So that you don't forget that great store that you discovered a few months ago on ShopSuey, you can save the links in your user profile.",
            titTrio3: "Add your favorite stores",
            txtTrio3: "You can collaborate with those online stores that you think deserve to be on ShopSuey by adding the link to their store yourself.",
            titWhats: "What is ShopSuey?",
            subtitWhats: "ShopSuey is a social repository of online stores.",
            txtWhats1: "This is a collaborative project where users can participate freely by uploading links from online stores to ShopSuey. And they can do it as customers or as owners of the online store.",
            txtWhats2: "ShopSuey is responsible for receiving, organizing and evaluating these links to create a repository of online stores.",
            callProjectPage: "More information about the project?",
            btnProjectPage: "ShopSuey Project",
            callBusinessPage: "Do you have an online store?",
            btnBusinessPage: "ShopSuey for business",
            titSummary: "In summary",
            titTool: "A tool for companies",
            txtTool: "ShopSuey brings businesses closer to potential customers in a natural way. A user searches for something that interests him and ShopSuey proposes companies where to find it.",
            titDiffusion: "Diffusion of online commerce",
            txtDiffusion: "What we are looking for in ShopSuey is to give greater visibility to companies. To do this, we provide them with a company profile that they can modify to improve their presence in user searches.",
            titProducts: "Products and services",
            txtProducts: "ShopSuey simplifies the results of searches made to companies that sell products and/or services.",
            titValidated: "Validated companies",
            txtValidated1: "All company profiles that are registered with ShopSuey are manually validated. That means that we have staff willing to review company profiles and verify that the information provided is real and truthful.",
            txtValidated2: "What this action gives us is security, for both parties, and confidence in user searches.",
            txtValidated3: "In addition, validated profiles are preferred in search results.",
            titBreak: "Breaking news!",
            txtBreak1: "Currently, ShopSuey is an MVP. That is, a project launched with the minimum to validate a hypothesis. And that means it's not perfect but it does what it's supposed to do.",
            txtBreak2: "What we want is that as a user, if you like what you see, you share it . And if you don't like it tell us why. And if you are a company, what we want is your vote of confidence. This is a project designed by and for you."
        },
        business: {
            header: "ShopSuey for businesses",
            btnActionRegin: "Create Company Profile",
            titWhats: "What is ShopSuey for businesses?",
            txtWhats: "ShopSuey is a platform for companies to attract potential customers and an opportunity to promote their brand.",
            titKey: "Keywords are the key",
            txtKey: "At ShopSuey, the control of keywords is completely yours. You decide which concepts best represent your product and/or service.",
            titValid: "Is my link valid for ShopSuey?",
            txtValid1: "If what your link offers is the sale of a product or service, I confirm that yes, your link is valid.",
            txtValid2: "In reality, practically any link could be said to offer something of both, but we simply filter out those that are directly dedicated to the business.",
            titAdvantages: "Advantages of ShopSuey",
            titMulti: "Multi language",
            txtMulti: "One of the advantages of working with keywords is that you can use any language and search for a more specific target. This generates trust in users who see a company that speaks their same language as closer.",
            titKeywords: "Keyword management",
            txtKeywords: "Keywords are what make a link appear in a user's searches. In a direct way they define your company and the product or service you offer. It is the key to searches and you can manage them from a company profile.",
            titTest: "Test keywords instantly",
            txtTest: "You can add and change keywords as many times as you need. This way you can carry out tests and assess which keywords have worked best and which ones are worth changing.",
            titPromotion: "Promotion",
            txtPromotion: "Updating keywords has an immediate effect on user searches and that is an advantage because it allows you to adjust to a calendar and, for example, better serve a campaign such as Christmas or Black Friday or change the keywords to a single day such as Mother's Day.",
            titBookmarks: "Bookmarks",
            txtBookmarks: "All user profiles have a space where you can save the links of those companies with a single click. This detail can help a user go to your link rather than perform a new search for something you offer in your company.",
            titCallActionProfile: "Are you interested?",
            subCallActionProfile: "Create your company profile?",
            txtCallActionProfile1: "To obtain your company profile you must:",
            btnSignup: "Sign up",
            txtCallActionProfile2: "And if you already have a user profile, you just have to go to your profile and claim ownership of the link.",
            titProcedure: "The ShopSuey procedure",
            txtProcedure: "As you already know, ShopSuey validates the links it receives. That means that there is a short period of time from when a user uploads a link until it is available in the search engine. The procedure that ShopSuey follows is as follows:",
            titUploads: "A user uploads a link",
            txtUploads: "The proposed link is saved in a list waiting to be reviewed.",
            titReview: "Review of the link and its keywords",
            txtReview1: "To all proposed links the user is asked to add a series of keywords that they consider interesting to reference the link.",
            txtReview2: "The ShopSuey review includes review of the link content and a review of the proposed keywords.",
            titValidation: "Link validation",
            txtValidation: "If the content of the link fits the conditions accepted by ShopSuey, the validation of the link and we will inform the user who uploaded the link that the store is now available in the ShopSuey search engine.",
            titClaim: "Ownership claim",
            txtClaim1: "If during the review period a user claims ownership of the link, ShopSuey will not modify the business profile information or keywords because it understands that there is already an administrator user for the business profile.",
            txtClaim2: "What will continue in process is the validation of the content of the link and, once validated, we will inform both the user who proposed the link and the administrator of the company profile that the link is now available in the ShopSuey search engine.",
            titBreak: "Breaking news!",
            txtBreak1: "Currently, ShopSuey is an MVP. That is, a project launched with the minimum to validate a hypothesis. And that means it's not perfect but it does what it's supposed to do.",
            txtBreak2: "What we want is that as a user, if you like what you see, you share it . And if you don't like it tell us why. And if you are a company, what we want is your vote of confidence. This is a project designed by and for you."

        },
        contact: {
            titContact: "Contact us",
            labelName: "Contact name",
            labelEmail: "Email address",
            labelMessage: "Message",
            labelPermission: "I agree with privacy policy",
            btnSend: "Send",
            titWhat: "Whats up?",
            titBug: "Do you find a bug?",
            titBusiness: "Do you want to talk about business?",
            titWeather: "Do you want to talk about weather?",
            titFollow: "Do you want to follow us?",
            txtFollow: "On twitter or X or.... there! ",
            txtSendto: "Send a email to ",
            formResponseTit: "Thanks for contact us!",
            formResponseTxt: "We will contact you shortly. Cheers!!",

        },
        user: {
            home: {
                titValidate: "Verify status",
                txtValidate: "To validate your account we need to verify your access email and ownership of the domain",
                titEmailVal: "Email account validation",
                txtEmailValA: "Didn't you receive it?",
                btnResend: "Resend email",
                txtCheckEmail: "Check your email and validate your access email",
                titUser: "User data",
                titAccess: "Access data",
                labelEmail: "Email account",
                labelPsw: "Password",
                btnChangePsw: "Change password",
                labelName: "Name",
                labelSurname: "Surname",
                labelNick: "Nick",
                labelOccupation: "Occupation",
                btnConfirm: "Confirm User changes",
                btnCancel: "Remove User changes",

            },
            companyHelp: {
                titHelp1: "Do you know the perfect online store?",
                titHelp2: "Do you want to help them grow?",
                titUpload: "Upload a link",
                btnUpload: "Upload",
                titShare: "Share ShopSuey",
                btnShare: "Share Shopsuey",
                titCompany: "Are you a company?",
                btnClaim: "Claim your link",
            },
            links: {
                titLinks: "Links list",
                titMyLinks: "My Links",
                labelFilter: "Filter",
                messageNothing: "Nothing to see",
            },
            company: {
                titProfile: "Profile data",
                titCompany: "Company data",
                labelCompanyName: "Company name",
                labelCompanyNick: "Company nickname",
                labelCompanyEmail: "Company email",
                labelCompanyLink: "Company link",
                labelCountry: "Country",
                labelProductType: "Product type",
                labelDescription: "Company description",
                btnProfileConfirm: "Confirm Profile changes",
                btnProfileRemove: "Remove Profile changes",
                titKeywords: "Keywords",
                labelTxtKeywords: "Remember that maximum is 10 keywords",
                labelTxtKeywords2: "(use tab or comma to create keyword)",
                btnKeyConfirm: "Confirm Keywords changes",
                btnKeyRemove: "Remove Keywords changes",
                labelSelectCountry: "Select your preferential country of sale",
            },
            companyVerify: {
                titComplete: "Complete the following steps to verify link ownership",
                txtComplete: "After verification you will be able to manage your link",
                titDomain: "Domain ownership",
                txtDomain: "Copy and paste this code in your website just before </head>",
                labelCopy: "Copy code!",
                titCheck: "Check code",
                txtCheck: "If you have already placed the code on your website, check and obtain ownership of your link",
                btnCheck: "Check owner tag"

            }

        }

    },
    es: {
        hello: '¡Hola Mundo!',
        what: '¿Qué estás buscando?',
        searchMessage: 'Busca por producto, servicio, tipo de tienda,...',
        businessClaim: '¿Quieres ver tu enlace en ShopSuey?',
        buttonClaim: '¡Descubre ShopSuey!',
        suggestions: 'Suggestions',
        businesInfo: 'Business Info',
        entry: 'Entrar',
        footerHome: 'Inicio',
        footerProject: 'El Proyecto',
        footerBusiness: 'Business',
        footerContact: 'Contacto',
        footerLogin: 'Entrar',
        footerSignup: 'Regístrarse',
        footerMeet: 'Descubre ShopSuey',
        footerTerms: 'Términos',
        footerCookies: 'Cookies',
        footerPrivacy: 'Privacidad',
        login: 'Entrar',
        buttonLogin: 'Entrar',
        loginTitle: 'Entrar',
        textEmail: 'Correo electrónico',
        labelPsw: 'Contraseña',
        loginForget: '¿Has olvidado la contraseña?',
        loginYet: "¿Todavía no tienes cuenta?",
        buttonRecover: '¡Recupérala!',
        buttonSignup: '¡Regístrate!',
        recoverTitle: 'Recuperar contraseña',
        buttonSend: 'Enviar',
        recoverRemember: '¿Ya te acuerdas?',
        recoverSignup: "¿Todavía no tienes cuenta?",
        recoverCheckmail: 'Revisa tu correo',
        signupTitle: '¡Regístrate!',
        signupSubtitle: "Crea tu cuenta ¡Es gratis!",
        labelName: 'Nombre',
        labelEmail: 'Correo electrónico',
        labelPswRemember: "Recuerda: 8 dígitos mínimo (Mayúsculas, minúsculas y números)",
        labelCompanyQuestion: "¿Eres una empresa? ¿Quieres subir el enlace de tu negocio?",
        inputYes: 'Sí',
        inputNo: 'No',
        labelNameShop: 'Nombre empresa',
        labelLink: 'Enlace',
        labelKeywords: 'Palabras clave',
        labelKeyMessage: "(Usa tabulador o coma para crear cada palabra clave)",
        buttonCreateAccount: 'Crear cuenta',
        titleThanksSignup: '¡Gracias por registrarte!',
        msgThanksSignup: 'Ahora revisa tu correo y verifica tu cuenta ¡Hasta pronto!',

        common:{
            buttonCloseWindow: "Cerrar ventana",
        },
        form: {
            labelLink: "Enlace",
            labelCompanyLink: "Enlace de tu empresa",
            errorOwnerLink1: "Este enlace no se encuentra en ShopSuey",
            errorOwnerLink2: "Este enlace tiene un administrador",
            buttonCreateOwner: "Crear perfil propietario",
            errorCompanyLink: "Para crear una cuenta de propietario primero deberás subir el enlace de la compañía a ShopSuey.",
            buttonUploadLink: "Subir el enlace de la compañía",
            labelCurrentPassword: "Contraseña actual",
            labelNewPassword: "Nueva contraseña",
            labelRepeatPassword: "Repite la nueva contraseña",
            errorMatchPasswords: "Las contraseñas no coinciden",
            errorPasswordInvalid: "La contraseña no es válida. Prueba de nuevo.",
            buttonChangePassword: "Cambiar contraseña",
            labelNameShop: "Nombre de la compañía",
            errorRequired: "Este campo es obligatorio",
            errorSize: "Es demasiado corto",
            errorNotLink: "Este no es un enlace",
            errorLinkInShopsuey: "Este enlace está en ShopSuey.",
            labelKeywords: "Palabras clave",
            labelKeywordsSmall: "(use tabulación o coma para crear palabras clave)", 
            labelTypeContact: "¿De qué quieres hablar?",
            optionTypeContact1: "Simplemente quiero hablar",
            optionTypeContact2: "Hablemos de negocios",
            optionTypeContact3: "Quiero reportar un error",
        },
        modal:{
            conditions: {
                title: 'Condiciones',
                textDescription: "Para subir un enlace a ShopSuey deberías conocer las siguientes condiciones:",
                textList1: "El sitio web propuesto debe ofrecer al menos un producto o servicio.",
                textList2: "El sitio web debe tener carácter comercial.",
                textList3: "El enlace debe ser un dominio, sin subcarpetas ni parámetros",
                textList4: "El enlace no puede ser un subdominio.",
                textList5: "Los enlaces redirigidos serán rechazados",
                textList6: "No se aceptarán sitios web agregadores",
            },
            owner: {
                title: "Reclamar la propiedad",
                textDescription: "Si eres el dueño de una de las webs agregadas en ShopSuey puedes reclamar la propiedad y crear un perfil de empresa.",
                textDescription2: "Un perfil de empresa te permite controlar las palabras clave, gestionar tu información, preparar campañas, y mucho más para tu negocio.",
                titleThanks: "¡Gracias!",
                textThanks: "Nuestro equipo está validando su enlace. En cuanto lo validemos te enviaremos un correo electrónico. ¡Gracias por tu ayuda!",
                titleOwner: "Ya dispones de una propiedad",
                subtitleOwner: "Y puedes acceder a ella a través del menú superior -> ",
            },
            password: {
                title: "Cambia tu contraseña",
                remember: "¡Recuerda! Mínimo 8 caracteres, al menos una letra mayúscula, una letra minúscula y un número.",
                thanks: "¡Felicidades! Su contraseña ha sido cambiada correctamente."

            },
            savelink:{
                title: "¡No pierdas ni un enlace!",
                subtitle: "Guarda todos los enlaces que amas",
                txtDescription: "Esta es una de las muchas ventajas de registrarse en ShopSuey.",
                txtClaim: "¡Vamos! ¿Que estas esperando?",
                buttonSignup: "Regístrate",
            },
            sharelink:{
                title: "¡Comparte ShopSuey!",
                subtitle: "Save all store links you love",
                txtDescription: "Hay infinidad de tiendas desconocidas en la red. Si conoces a alguno de ellos envíale directamente nuestro enlace o publícalo en tus redes sociales y ayúdanos a crecer juntos.",
                txtClaim: "¡Copia y pega este enlace en todo el mundo!",
                web: "ShopSuey.com",
                buttonCopy: "¡Copiar enlace ShopSuey!",
            },
            uploadlink:{
                title: "Subir un enlace de empresa",
                txtDescription: "Puedes contribuir subiendo el enlace de esa tienda que te tiene enamorado. En ShopSuey revisamos los enlaces que nos envían los usuarios y validamos su contenido. Cuando el enlace esté validado te lo notificaremos por correo electrónico. ¡Así de fácil!",
                thanks: "¡Gracias!",
                txtThanks: "Nuestro equipo está validando su enlace. En cuanto lo validemos te enviaremos un correo electrónico. ¡Gracias por tu ayuda!",
            },
        },
        regin: {
            business_box: {
                textMessage1: '¿Eres una empresa?',
                textMessage2: 'Estás en el buen camino',
                textRemember: '¡Recuerda!',
                txt1: 'Crea una cuenta personal',
                txt2: 'Sube el enlace de tu empresa',
                txt3: 'Reclama la propiedad',
                textAccount: '¿Ya tienes cuenta personal?',
            }
        },
        
        project: {
            header: 'El proyecto ShopSuey',
            titWhat: '¿Qué es ShopSuey?',
            txtWhat: 'ShopSuey es un repositorio social de empresas con web propia.',
            titRepo: '¿Un repositorio social?',
            txtRepo1: 'Sí, En ShopSuey son usuarios y empresas los que suben enlaces a nuestro repositorio.',
            txtRepo2: 'Todos los enlaces son revisados y aprobados manualmente porque queremos proporcionar calidad antes que cantidad.',
            titWhy: '¿Por qué ShopSuey?',
            txtWhy1: 'Artesanos, artistas, creadores de productos increíbles. Son innumerables las profesiones que reivindican la calidad por encima de la cantidad y sus voces se pierden en los buscadores en favor de las empresas de mayor tamaño.',
            txtWhy2: "Lo que proponemos en ShopSuey es simplemente dar a las empresas, grandes y pequeñas, la posibilidad de definir ellas mismas sus empresas y decidir con qué palabras clave quieren ser descubiertas. Se trata de cambiar las reglas para no depender de un algoritmo y encontrar una forma más consistente de conectar a los clientes potenciales con las empresas adecuadas.",
            titOffer: "¿Qué ofrece ShopSuey?",
            txtOffer1: "ShopSuey es una oportunidad promocional para las empresas, una plataforma para atraer clientes potenciales. Y al mismo tiempo, es un espacio para que los clientes encuentren empresas especializadas en lo que buscan. Simple.",
            txtOffer2: "Validamos todos los enlaces que tanto usuarios como empresas publican en ShopSuey porque lo que buscamos es que los resultados de búsqueda sean exclusivamente de empresas que ofrecen un producto o servicio específico.",
            titHow: "¿Como funciona?",
            forClients: "Para clientes",
            forCompanies: "Para compañías",
            iconSearch: "Buscar",
            iconSave: "Guardar",
            iconCollaborate: "Colaborar",
            iconPublish: "Publicar",
            iconManage: "Administrar",
            iconPromotion: "Promocionar",
            txtClients1: "Para aquellos usuarios que buscan un producto para comprar, ShopSuey funciona como cualquier buscador. Ingresas el nombre del producto o servicio que buscas y ShopSuey te ofrece un listado de empresas que están relacionadas con la búsqueda realizada.",
            txtClients2: "Además, si el usuario lo desea, puede guardar dicho enlace en su cuenta personal para consultarlo cuando quiera.",
            txtClients3: "Además, ShopSuey ofrece a los usuarios la posibilidad de apoyar a las empresas que cuenten con un sitio web que cumpla con los requisitos. De esta forma y a través de un sencillo formulario, un usuario nos envía su propuesta y la revisamos y si el enlace cumple con los requisitos publicamos el enlace en ShopSuey.",
            txtCompanies1: "ShopSuey es un repositorio para empresas y todas aquellas que tengan un sitio web pueden reclamar la propiedad o subir su enlace de forma totalmente gratuita.",
            txtCompanies2: "Todas las empresas disponen de un perfil donde pueden personalizar todo lo que ShopSuey muestra sobre ellas (nombre de la empresa, descripción, etc.) y, lo más importante, controlar las palabras clave que relacionan las búsquedas de los usuarios con su empresa.",
            txtAdBusiness: "¿Eres una empresa? Te recomendamos seguir leyendo en el siguiente enlace:",
            btnAdBusiness: "ShopSuey para empresas",
            titDatabase: "Una base de datos social",
            txtDatabase1: "La lista de enlaces de ShopSuey es compilada por los miembros de ShopSuey y todos sus usuarios. Todos los enlaces propuestos se guardan y revisan independientemente de quién envió la propuesta.",
            txtDatabase2: "Una vez pasados los filtros, el enlace propuesto se publica en ShopSuey y se informa al usuario de la aprobación del enlace."
        },
        discover: {
            header: '¡Descubre ShopSuey!',
            subheader: "El repositorio social de las tiendas online",
            titTrio1: "Descubre tiendas online",
            txtTrio1: "En ShopSuey encontrarás tiendas online. Puedes buscar por producto, marca, servicio,... pero siempre encontrarás tiendas online.",
            titTrio2: "Guarda y organiza tus favoritos",
            txtTrio2: "Para que no olvides esa tienda que descubriste hace unos meses, en ShopSuey puedes guardar los enlaces en tu perfil de usuario.",
            titTrio3: "Añade tus tiendas favoritas",
            txtTrio3: "Puedes colaborar con aquellas tiendas online que creas que merecen estar en ShopSuey añadiendo tú mismo el enlace a su tienda.",
            titWhats: "¿Qué es ShopSuey?",
            subtitWhats: "ShopSuey es un repositorio social de tiendas online.",
            txtWhats1: "Este es un proyecto colaborativo donde los usuarios pueden participar libremente subiendo enlaces de tiendas online a ShopSuey. Y pueden hacerlo como clientes o como propietarios de la tienda online.",
            txtWhats2: "ShopSuey se encarga de recibir, organizar y evaluar estos enlaces para crear un repositorio de tiendas online.",
            callProjectPage: "¿Más información sobre el proyecto?",
            btnProjectPage: "Proyecto ShopSuey",
            callBusinessPage: "¿Tienes una tienda online?",
            btnBusinessPage: "ShopSuey para empresas",
            titSummary: "En resumen",
            titTool: "Una herramienta para las empresas",
            txtTool: "ShopSuey acerca las empresas a los clientes potenciales de forma natural. Un usuario busca algo que le interesa y ShopSuey le propone empresas donde encontrarlo.",
            titDiffusion: "Difusión del comercio online",
            txtDiffusion: "Lo que buscamos en ShopSuey es dar mayor visibilidad a las empresas con web propia. Para ello, les proporcionamos un perfil de empresa que podrán modificar para mejorar su presencia en las búsquedas de los usuarios.",
            titProducts: "Productos y servicios",
            txtProducts: "ShopSuey simplifica los resultados de las búsquedas realizadas a empresas que venden productos y/o servicios.",
            titValidated: "Empresas validadas",
            txtValidated1: "Todos los perfiles de empresas que están registrados en ShopSuey se validan manualmente. Eso significa que contamos con personal dispuesto a revisar los perfiles de las empresas y verificar que la información proporcionada es real y veraz.",
            txtValidated2: "Lo que nos aporta esta acción es seguridad, para ambas partes, y confianza en las búsquedas de los usuarios.",
            txtValidated3: "Además, los perfiles validados son preferentes en los resultados de una búsqueda.",
            titBreak: "¡Noticias de última hora!",
            txtBreak1: "Actualmente, ShopSuey es MVP. Es decir, un proyecto lanzado con lo mínimo para validar una hipótesis. Y eso significa que no es perfecto pero hace lo que se supone que debe hacer.",
            txtBreak2: "Lo que queremos es que como usuario, si te gusta lo que ves, lo compartas. Y si no te gusta cuéntanos por qué. Y si eres una empresa lo que queremos es tu voto de confianza. Este es un proyecto diseñado por y para ti."
        },
        business: {
            header: "ShopSuey para empresas",
            btnActionRegin: "Crear perfil de empresa",
            titWhats: "¿Qué es ShopSuey para empresas?",
            txtWhats: "ShopSuey es una plataforma para que las empresas atraigan clientes potenciales y una oportunidad para promocionar su marca.",
            titKey: "Las palabras clave son la clave",
            txtKey: "En ShopSuey, el control de las palabras clave es completamente tuyo. Tú decides qué conceptos representan mejor tu producto y/o servicio.",
            titValid: "¿Mi enlace es válido para ShopSuey?",
            txtValid1: "Si lo que ofrece tu enlace es la venta de un producto o servicio, te confirmo que sí, tu enlace es válido.",
            txtValid2: "En realidad se podría decir que prácticamente cualquier enlace ofrece algo de ambas, pero simplemente filtramos aquellos que están directamente dedicados al negocio.",
            titAdvantages: "Ventajas de ShopSuey",
            titMulti: "Multi lenguaje",
            txtMulti: "Una de las ventajas de trabajar con palabras clave es que puedes utilizar cualquier idioma y buscar un target más específico. Esto genera confianza en los usuarios que ven más cerca una empresa que habla su mismo idioma.",
            titKeywords: "Gestión de palabras clave",
            txtKeywords: "Las palabras clave son las que hacen que un enlace aparezca en las búsquedas de un usuario. De manera directa definen tu empresa y el producto o servicio que ofreces. Es la clave de las búsquedas y puedes gestionarlas desde un perfil de empresa.",
            titTest: "Testear palabras clave al instante",
            txtTest: "Puedes agregar y cambiar palabras clave tantas veces como necesites. De esta forma podrás realizar pruebas y valorar qué palabras clave han funcionado mejor y cuáles merece la pena cambiar.",
            titPromotion: "Promoción",
            txtPromotion: "Actualizar palabras clave tiene un efecto inmediato en las búsquedas de los usuarios y eso es una ventaja porque permite ajustarse a un calendario y, por ejemplo, servir mejor una campaña como Navidad o Black Friday o cambiar las palabras clave a un solo día como el Día de la Madre.",
            titBookmarks: "Marcadores",
            txtBookmarks: "Todos los perfiles de usuario disponen de un espacio donde poder guardar los enlaces de aquellas empresas con un solo clic. Este detalle puede ayudar a un usuario a acceder a su enlace en lugar de realizar una nueva búsqueda de algo que ofrece en su empresa.",
            titCallActionProfile: "¿Estás interesado?",
            subCallActionProfile: "Crea el perfil de tu empresa",
            txtCallActionProfile1: "Para obtener el perfil de su empresa debe:",
            btnSignup: "Regístrate",
            txtCallActionProfile2: "Y si ya tienes un perfil de usuario, sólo tienes que ir a tu perfil y reclamar la propiedad del enlace.",
            titProcedure: "El procedimiento ShopSuey",
            txtProcedure: "Como ya sabes, ShopSuey valida los enlaces que recibe. Eso significa que hay un corto período de tiempo desde que un usuario sube un enlace hasta que está disponible en el motor de búsqueda. El procedimiento que sigue ShopSuey es el siguiente:",
            titUploads: "Un usuario sube un enlace.",
            txtUploads: "El enlace propuesto se guarda en una lista en espera de ser revisado.",
            titReview: "Revisión del enlace y sus palabras clave.",
            txtReview1: "A todos los enlaces propuestos se le pide al usuario que añada una serie de palabras clave que considere interesantes para referenciar el enlace.",
            txtReview2: "La revisión de ShopSuey incluye una revisión del contenido del enlace y una revisión de las palabras clave propuestas.",
            titValidation: "Validación de enlaces",
            txtValidation: "Si el contenido del enlace se ajusta a las condiciones aceptadas por ShopSuey, se procederá a la validación del enlace y le informaremos al usuario que subió el enlace que la tienda ya se encuentra disponible en el buscador de ShopSuey.",
            titClaim: "Reclamación de propiedad",
            txtClaim1: "Si durante el período de revisión un usuario reclama la propiedad del enlace, ShopSuey no modificará la información del perfil comercial ni las palabras clave porque entiende que ya existe un usuario administrador para el perfil comercial.",
            txtClaim2: "Lo que continuará en proceso es la validación del contenido del enlace y, una vez validado, informaremos tanto al usuario que propuso el enlace como al administrador del perfil de la empresa que el enlace ya se encuentra disponible en el buscador de ShopSuey.",
            titBreak: "¡Noticias de última hora!",
            txtBreak1: "Actualmente, ShopSuey es MVP. Es decir, un proyecto lanzado con lo mínimo para validar una hipótesis. Y eso significa que no es perfecto pero hace lo que se supone que debe hacer.",
            txtBreak2: "Lo que queremos es que como usuario, si te gusta lo que ves, lo compartas. Y si no te gusta cuéntanos por qué. Y si eres una empresa lo que queremos es tu voto de confianza. Este es un proyecto diseñado por y para ti."

        },
        contact: {
            titContact: "Contáctanos",
            labelName: "Nombre de contacto",
            labelEmail: "Email de contacto",
            labelMessage: "Mensaje",
            labelPermission: "Estoy de acuerdo con la política de privacidad",
            btnSend: "Enviar",
            titWhat: "¿Qué tal?",
            titBug: "¿Has encontrado un error?",
            titBusiness: "¿Quieres hablar de negocios?",
            titWeather: "¿Quieres hablar del tiempo?",
            titFollow: "¿Quieres seguirnos?",
            txtFollow: "En twitter o X o...... Síguenos ",
            txtSendto: "Envía un email a ",
            formResponseTit: "¡Gracias por contactar!",
            formResponseTxt: "Te contestaremos pronto. ¡Saludos!",

        },
        user: {
            home: {
                titValidate: "Verificar estado",
                txtValidate: "Para validar su cuenta necesitamos verificar su correo electrónico de acceso y la propiedad del dominio.",
                titEmailVal: "Validación de cuenta de correo electrónico",
                txtEmailValA: "¿No lo recibiste?",
                btnResend: "Reenviar email",
                txtCheckEmail: "Revisa tu correo electrónico y valida tu correo electrónico de acceso",
                titUser: "Datos del usuario",
                titAccess: "Datos de acceso",
                labelEmail: "Cuenta de correo electrónico",
                labelPsw: "Contraseña",
                btnChangePsw: "Cambiar la contraseña",
                labelName: "Nombre",
                labelSurname: "Apellidos",
                labelNick: "Nick",
                labelOccupation: "Ocupación",
                btnConfirm: "Confirmar cambios de usuario",
                btnCancel: "Eliminar cambios de usuario",

            },
            companyHelp: {
                titHelp1: "¿Conoces la tienda online perfecta?",
                titHelp2: "¿Quieres ayudarlos a crecer?",
                titUpload: "Sube un enlace",
                btnUpload: "Subir",
                titShare: "Compartir ShopSuey",
                btnShare: "Compartir Shopsuey",
                titCompany: "¿Eres una empresa?",
                btnClaim: "Reclama tu enlace",
            },
            links: {
                titLinks: "Lista de enlaces",
                titMyLinks: "Mis enlaces",
                labelFilter: "Filtrar",
                messageNothing: "Nada que ver",
            },
            company: {
                titProfile: "Datos de perfil",
                titCompany: "Datos de la compañia",
                labelCompanyName: "Nombre de la compañia",
                labelCompanyNick: "Nickname de la compañia",
                labelCompanyEmail: "Email de la compañia",
                labelCompanyLink: "Enlace de la compañia",
                labelCountry: "País de la compañia",
                labelProductType: "Tipo de producto",
                labelDescription: "Descripción de la compañia",
                btnProfileConfirm: "Confirmar cambios de perfil",
                btnProfileRemove: "Eliminar cambios de perfil",
                titKeywords: "Palabras clave",
                labelTxtKeywords: "Recuerda que el máximo son 10 palabras clave.",
                labelTxtKeywords2: "(use tabulación o coma para crear palabras clave)",
                btnKeyConfirm: "Confirmar cambios de palabras clave",
                btnKeyRemove: "Eliminar cambios de palabras clave",
                labelSelectCountry: "Seleccione su país de venta preferencial",
            },
            companyVerify: {
                titComplete: "Complete los siguientes pasos para verificar la propiedad del enlace",
                txtComplete: "Después de la verificación podrás gestionar tu enlace.",
                titDomain: "Propiedad del dominio",
                txtDomain: "Copie y pegue este código en su sitio web justo antes de </head>",
                labelCopy: "¡Copia el código!",
                titCheck: "Código de verificación",
                txtCheck: "Si ya ha colocado el código en su sitio web, verifique y obtenga la propiedad de su enlace.",
                btnCheck: "Comprobar etiqueta de propietario"

            }

        }

    },
}

export const i18n = new VueI18n({
    // locale: 'es',
    locale: window.navigator.language.split('-')[0],
    fallbackLocale: 'en',
    messages
})