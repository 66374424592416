<template>
  <div class="col-12" id="big-container-content">
    <ConditionsInfo></ConditionsInfo>
    <div class="container">

      <div class="row align-items-center justify-content-center mt-3">
        <div class="col-12">
          <div class="row align-items-center justify-content-center mt-3">
            <div class="col-8 col-sm-8">
              <h1>{{ $t('business.header') }}</h1>
              <a href="/signup?business=cool" class="btn btn-primary btn-lg">{{ $t('business.btnActionRegin') }}</a>
            </div>
            <div class="col-4 col-sm-4">
              <img src="../assets/img/ico_search_shop.png" class="img-fluid" alt="Search Shop icon"/>
            </div>
          </div>  
        </div>
        
        <div class="col-12">
          <div class="row align-items-start justify-content-center mt-3">
            <div class="col-12 col-sm-6">
              <h2>{{ $t('business.titWhats') }}</h2>
              <p>{{ $t('business.txtWhats') }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <h2>{{ $t('business.titKey') }}</h2>
              <p>{{ $t('business.txtKey') }}</p>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <div class="col-12">
          <div class="row align-items-center justify-content-center mt-3">
            <div class="col-12 col-sm-6">
              <h2>{{ $t('business.titValid') }}</h2>
              <p>{{ $t('business.txtValid1') }}</p>
              <p>{{ $t('business.txtValid2') }}</p>
            </div>
            <div class="col-12 col-sm-6 text-center">
              <i class="far fa-check-circle" style="font-size: 5em;"></i>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <div class="col-12">
          <div class="row align-items-center justify-content-start mt-3">
            <div class="col-12">
              <h2>{{ $t('business.titAdvantages') }}</h2>
            </div>
            <div class="col-12 col-sm-6">
              <h3>{{ $t('business.titMulti') }}</h3>
              <p>{{ $t('business.txtMulti') }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <h3>{{ $t('business.titKeywords') }}</h3>
              <p>{{ $t('business.txtKeywords') }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <h3>{{ $t('business.titTest') }}</h3>
              <p>{{ $t('business.txtTest') }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <h3>{{ $t('business.titPromotion') }}</h3>
              <p>{{ $t('business.txtPromotion') }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <h3>{{ $t('business.titBookmarks') }}</h3>
              <p>{{ $t('business.txtBookmarks') }}</p>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <div class="col-12">
          <div class="row align-items-center justify-content-center ">
            <div class="col-12 col-sm-6 box_white_presentation">
              <div class="p-3 text-center">
                <h2>{{ $t('business.titCallActionProfile') }}</h2>
                <h2>{{ $t('business.subCallActionProfile') }}</h2>
                <a class="btn btn-primary" href="/signup?business=cool">{{ $t('business.btnSignup') }}</a>
                <p class="mt-4">{{ $t('business.txtCallActionProfile2') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <div class="col-12">
          <div class="row align-items-center justify-content-center mt-3">
            <div class="col-12">
              <h2>{{ $t('business.titProcedure') }}</h2>
              <p>{{ $t('business.txtProcedure') }}</p>
            </div>

            <div class="col-12">
              <h3>{{ $t('business.titUploads') }}</h3>
              <p>{{ $t('business.txtUploads') }}</p>
            </div>

            <div class="col-12">
              <h3>{{ $t('business.titReview') }}</h3>
              <p>{{ $t('business.txtReview1') }}</p>
              <p>{{ $t('business.txtReview2') }}</p>
            </div>

            <div class="col-12">
              <h3>{{ $t('business.titValidation') }}</h3>
              <p>{{ $t('business.txtValidation') }}</p>
            </div>

            <div class="col-12" id="claim">
              <h3>{{ $t('business.titClaim') }}</h3>
              <p>{{ $t('business.txtClaim1') }}</p>
              <p>{{ $t('business.txtClaim2') }}</p>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <div class="spacer"></div>
      
      <div class="row align-items-top justify-content-center">
        <div class="col-12 col-sm-12 mt-5 box_white_presentation p-3">
          <div class="row align-items-center justify-content-center">
            <div class="col-2 text-center">
              <i class="far fa-newspaper" style="font-size: 4em;"></i>
            </div>
            <div class="col-10">
              <h2 class="mb-3">{{ $t('business.titBreak') }}</h2>
              <p>{{ $t('business.txtBreak1') }}</p>
              <p>{{ $t('business.txtBreak2') }}</p>
            </div>
          </div>
        </div>
      </div>
       -->

      <div class="spacer"></div>

    </div>
  </div>
</template>
<script>
import ConditionsInfo from './modals/ConditionsInfo.vue';

export default {
  name: "ShopHome",
  components: {
    ConditionsInfo
  },
  mounted: function(){
    
  },
  methods: {
    
  }

};

</script>
<style scoped>
#arrowdiv{
  height: 50px;
}
.arrowCta {
  display: block;
  height: 13px;
  width: 13px;
  border: 11px solid transparent;
  transform: rotate(45deg);
  position: relative;
  margin: -2vh auto;
}

.arrowCta:after,
.arrowCta:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowCta:after {
  border-bottom: 3px solid #000000;
  border-right: 3px solid #000000;
  top: 0;
  left: 0;
  opacity: 1;
  animation: bottom-arrow 1.65s infinite;
}

@keyframes bottom-arrow {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
}

.arrowCta:before {
  top: 0;
  left: 0;
  border-bottom: 3px solid #000000;
  border-right: 3px solid #000000;
  animation: top-arrow 1.65s infinite;
}

@keyframes top-arrow {
  0% {
    transform: translate(-6px, -6px);
  }
  35% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (max-width: 576px) {
  #arrowdiv{
    display: none;
  }
}
</style>