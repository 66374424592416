<template>
  <div id="searcher">
    <div class="d-block" style="min-height: 100vh;" id="big-container-content">
      <div class="row align-items-center justify-content-center" style="min-height: 70vh;">
        <div class="col-11 col-sm-10 text-center">
          <h1>{{ $t('what') }}</h1>
          <p class="text-center small mt-1 mb-3"><i class="far fa-hand-point-right"></i> {{ $t('searchMessage') }}</p>
          <div id="tagdiv">
            <span class="rounded-pill btn btn-lg text-white ms-3 opacityout" id="tagName" @click="goToSearch(tagName)" >{{ tagName }}</span>
          </div>
          
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="d-block" style="position: absolute; bottom: 10px;">
          <p class="text-center small">
            <i class="far fa-lightbulb"></i> {{ $t('businessClaim') }}
          </p>
          <p class="text-center">
            <router-link to="/shop" class="btn btn-dark btn-sm">{{ $t('buttonClaim') }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { required, minLength } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "SearcherTemplate",
  components: {
    
  },
  validations: {
    search_input: {
      required,
      minLength: minLength(2),
    }
  },
  data(){
    return {
      submitted: false,
      sessionExist: false,
      nick_session: '',
      search_input: '',
      tagName: '',
      tagId: 0
    }
  },
  beforeMount: function(){
    if(this.$session.exists() === true){
      this.sessionExist = true;
      this.nick_session = this.$session.get('nick');
    }

    axios.get('https://shopsuey.net/code/test/get_top_tags.php')
    .then((response) => {
      let topTags = response.data;
      let j = Math.floor(Math.random() * topTags.length);
      this.tagName = topTags[j];
      return topTags
    })
    .then((topTags)=>{
      this.anima(topTags);
    })
    .catch(function(error){
      console.log(error);
    });
    
  },
  beforeUpdate: function(){
    if(this.$session.exists() === true){
      this.sessionExist = true;
      this.nick_session = this.$session.get('nick');
    }
  },
  mounted: function() {
    let inputSearch = document.getElementsByClassName('searcher_input')[0];
    setTimeout(()=>{
      inputSearch.focus({focusVisible: true});
    },800);

  },

  methods: {
    anima(a){
      const name = document.getElementById('tagName');
      name.classList.remove('opacityout');

      let bucle = setInterval(() => {
        let j = Math.floor(Math.random() * a.length);
        name.classList.add('opacityout');
        setTimeout(()=>{
          name.textContent = a[j];
          this.tagName = name.textContent;
        },300);
        setTimeout(()=>{
          name.classList.remove('opacityout');
        },300);
      },5000);
      
      name.addEventListener("mouseover", ()=>{
        clearInterval(bucle);
        clearTimeout(bucle);
      });
      name.addEventListener("mouseout", ()=>{
        setInterval(this.anima(a));
      });

    },
    goToSearch(a){
      this.$router.push('/search/' + a);
      this.$router.go('/search/'+ a);
    }
    
  }
};
</script>

<style>
#tagdiv{
  position: relative;
  min-height: 100px;
  text-align: center;
}
#tagName{
  transition: 0.2s;
  background-color: black;
  opacity: 1;
}
#tagName:hover{
  transition: 0.2s;
  background-color: red;
  opacity: 0.8;
}
.opacityout{
  transition: 0.2s !important;
  margin-top: 10px !important;
  opacity: 0 !important;
  

}
</style>