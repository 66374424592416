<template>
  <div>

    <ShareLink></ShareLink>
    <UploadLinkCompany></UploadLinkCompany>
    <OwnerLink></OwnerLink>

    
    <div class="col-12 box_border pt-5 pb-5">
      <div class="row align-items-start justify-content-center">

        <!-- Send link -->
        <div class="col-12">
          <h2 class="text-center mb-3">{{ $t('user.companyHelp.titHelp1') }}<br>{{ $t('user.companyHelp.titHelp2') }}</h2>
          <div class="row align-items-top justify-content-center">
            <div class="col-12 col-sm-4">
              <div class="row align-items-center justify-content-center">
                <div class="col-12 text-center">
                  <div class="p-3">
                    <i class="fas fa-hand-holding-heart" style="font-size: 5em;"></i>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <h3>{{ $t('user.companyHelp.titUpload') }}</h3>
                  <button class="btn btn-primary btn-lg"  data-bs-toggle="modal" data-bs-target="#upload_link_company">{{ $t('user.companyHelp.btnUpload') }}</button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="row align-items-center justify-content-center">
                <div class="col-12 text-center">
                  <div class="p-3">
                    <i class="far fa-kiss-wink-heart" style="font-size: 5em;"></i>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <h3>{{ $t('user.companyHelp.titShare') }}</h3>
                  <button class="btn btn-primary btn-lg"  data-bs-toggle="modal" data-bs-target="#share_link">{{ $t('user.companyHelp.btnShare') }}</button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="row align-items-center justify-content-center">
                <div class="col-12 text-center">
                  <div class="p-3">
                    <i class="fas fa-store" style="font-size: 5em;"></i>
                    
                  </div>
                </div>
                <div class="col-12 text-center">
                  <h3>{{ $t('user.companyHelp.titCompany') }}</h3>
                  <button class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#owner_link">{{ $t('user.companyHelp.btnClaim') }}</button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
          
      </div>
    </div>

  </div>
</template>
<script>

import ShareLink from './modals/ShareLink.vue';
import UploadLinkCompany from './modals/UploadLink.vue';
import OwnerLink from './modals/OwnerLink.vue';

export default {
  name: "UserCompany",
  components:{
    UploadLinkCompany,
    OwnerLink,
    ShareLink
  },
  data(){
    return {
      
    }
  },
  beforeMount: function() {

    
  },
  methods: {
    
  }
};


</script>