<template>
  <div class="row align-items-center justify-content-center">
    <div class="col-10">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 text-center" v-if="validated == true && submit == true">
          <h2>Thanks for verify your email</h2>
          <p>Now you are redirecting to ShopSuey home</p>
        </div>
        <div class="col-12 text-center" v-if="validated == false && submit == true">
          <h2>We are sorry</h2>
          <p>Something goes wrong!</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: "ValidationOwn",
  components:{
    
  },
  
  data(){
    return {
      code: '',
      validated: '',
      submit: false
    }
  },
  mounted(){
    this.code = this.$route.params.cd;
    axios.post('https://shopsuey.net/code/verifyemailown.php',{
      code: this.code
    })
    .then((response) => {
      
      if(response.data.message == 'ok'){
        this.validated = true;
        this.submit = true;
        setTimeout(()=>{
          this.$router.push({name: 'ShopHome'}) 
        },5000);
      }else{
        this.validated = false;
        this.submit = true;
      }
    })
    .catch(function(error){
      console.log(error);
    });

  },

};

</script>