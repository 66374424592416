<template>
  <div class="col-10">
    <div id="searcher" class="row align-items-center justify-content-center">
      <h1 class="text-center">
        <img src="../assets/img/shopsuey_logo.png" class="img-fluid" alt="ShopSuey" />
      </h1>
      <form>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-6">
            <div class="mb-4 input-group">
              <label for="search_input" class="form-label d-none"
                >Searcher</label
              >
              <i class="fas fa-search icon_before"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                id="search_input"
              />
            </div>
            <div class="text-center">
              <button class="btn btn-primary text-center">
                Find online shop
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="row align-items-center justify-content-center mt-5">
      <p class="text-center small">
        <i class="far fa-hand-point-right"></i> Search per keywords and separate
        using commas.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutTemplate",
  //   props: {
  //     msg: String
  //   }
};
</script>