<template>
  <div class="col-12" id="big-container-content">
    
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-10 col-12 mb-4">
        <div class="row align-items-center justify-content-center">

          <!-- Links list -->
          <div class="col-12">
            <h3>{{ $t('user.links.titLinks') }}</h3>
          </div>
          
          <div class="col-12 p-4 mb-3 box_gray_soft">
            <div class="row mb-3">
              <div class="col-6">
                <h4>{{ $t('user.links.titMyLinks') }}</h4>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center justify-content-end">
                  {{ $t('user.links.labelFilter') }} <i class="fa-heart me-4 ms-1" v-bind:class="onlyFav == true ?'fas color':'far'" @click="showFav()"></i>
                </div>
              </div>
            </div>
            <div class="box_white" v-if="matches == 'true'" >
              
              <paginate name="dataResults" :list="linksList" :per="10" class="paginate-list list-unstyled p-2 mb-0">
                <li class="d-flex align-items-center justify-content-between mb-3" v-for="(linksList, index) in paginated('dataResults')" :key="index" v-bind:class="(onlyFav == true && linksList.fav.includes('0')) ? 'd-none' : '' " :id="'line_' + linksList.id" >
                  <div>
                    <a class="text-decoration-none" :href="linksList.link + '/?ref=shopsuey'" target="_blank"><i class="fas fa-hand-point-right me-2"></i>{{linksList.link}}</a>
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="fa-heart me-4" v-bind:class="linksList.fav.includes('1')?'fas color':'far'" @click="updateFav(linksList.id_store)"></i>
                    <div class="form-check form-switch me-2">
                      <input class="form-check-input" type="checkbox" role="switch" @click="showDelete(linksList.id);">
                    </div>
                    <button class="btn btn-primary" :id="'delete_check_' + linksList.id" @click="deleteLink(linksList.id)" disabled><i class="fas fa-trash-alt"></i></button>
                  </div>
                </li>
              </paginate>
              <div class="col-12 text-center mt-3">
                <paginate-links for="dataResults" :limit="5" :show-step-links="true" class="pagination"></paginate-links>
              </div>
            </div>
            <div v-else-if="matches == 'false'" class="text-center p-4 box_white">
              <h2>{{ $t('user.links.messageNothing') }}</h2>
            </div>
            <div v-else class="text-center p-4 box_white">
              <h2>Loading</h2>
            </div>
          </div>
            
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: "UserLinks",
  components:{
    
  },
  
  data(){
    return {
      linksList:[],
      paginate: ['dataResults'],
      onlyFav: false,
      matches: '',
    }
  },
  beforeMount: function() {

    this.url = this.$route.path;

    if(this.$session.exists()){
      
      // get fav
      axios.post('https://shopsuey.net/code/simply/get_data_user_list.php',{
        id: this.$session.get('id'),
      })
      .then((response) => {
        if(response.data != null) {
          this.linksList = response.data;
          this.matches = 'true';
        }else{
          this.linksList = [];
          this.matches = 'false';
        }
        
      })
      .catch(function(error){
        console.log(error);
      });

    }else{
      this.$router.push('/login');
    }
    
  },
  methods: {
    
    showFav(){
      if(this.onlyFav == false){
        this.onlyFav = true;
      }else{
        this.onlyFav = false;
      }
    },

    showDelete(a){
      let delButton = document.getElementById('delete_check_' + a);
      if(delButton.disabled){
        delButton.disabled = false;
      }else{
        delButton.disabled = true;
      }
    },

    deleteLink(a){
      if(this.$session.exists() === true){

        axios.post('https://shopsuey.net/code/simply/delete_link_user_list.php',{
          id: a
        })
        .then((response) => {
          if(response.data == 'ok') {
            let line = document.getElementById('line_' + a);
            line.remove();
            let linescount = document.querySelectorAll('.paginate-list li');
            if(linescount.length == 0) {
              this.matches = 'false';
            }
          }else{
            console.log(response.data);
          }
        })
        .catch(function(error){
          console.log(error);
        }); 
      }
    },

    updateFav(a){
      
      if(this.$session.exists() === true){

        axios.post('https://shopsuey.net/code/simply/update_user_fav.php',{
          id: this.$session.get('id'),
          fav: a
        })
        .then((response) => {
          this.linksList = response.data;
        })
        .catch(function(error){
          console.log(error);
        }); 
      }
      
    },
    
    getUrl(){
      this.$router.go();
    },

  }
};


</script>