<template>
  <div class="col-12" id="big-container-content">
    <div class="container">

      <div class="row align-items-start justify-content-center mt-3">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-evenly mt-5">
            <h1>{{ $t('project.header') }}</h1>
            <img src="../assets/img/logo_shop_suey.png" class="img-fluid" alt="Logo ShopSuey" />
          </div>
        </div>
        
        <div class="col-12">
          <h2>{{ $t('project.titWhat') }}</h2>
          <p>{{ $t('project.txtWhat') }}</p>
        </div>

        <div class="spacer_sm"></div>

        <div class="col-12">
          <h2>{{ $t('project.titRepo') }}</h2>
          <p>{{ $t('project.txtRepo1') }}</p>
          <p>{{ $t('project.txtRepo2') }}</p>
        </div>

        <div class="spacer_sm"></div>

        <div class="col-12">
          <h2>{{ $t('project.titWhy') }}</h2>
          <p>{{ $t('project.txtWhy1') }}</p>
          <p>{{ $t('project.txtWhy2') }}</p>
        </div>

        <div class="spacer_sm"></div>

        <div class="col-12">
          <h2>{{ $t('project.titOffer') }}</h2>
          <p>{{ $t('project.txtOffer1') }}</p>
          <p>{{ $t('project.txtOffer2') }}</p>
        </div>

        <div class="spacer_sm"></div>

        <div class="col-12">
          <h2>{{ $t('project.titHow') }}</h2>
          <div class="row align-items-start justify-content-center mt-3">
            <div class="col-12 col-sm-6">
              <h3 class="text-center mt-3">{{ $t('project.forClients') }}</h3>
              <div class="d-flex justify-content-around text-center p-3 pb-0">
                <div>
                  <i class="fas fa-search" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconSearch') }}</h3>
                </div>
                <div>
                  <i class="fas fa-paperclip" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconSave') }}</h3>
                </div>
                <div>
                  <i class="fas fa-share-alt" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconCollaborate') }}</h3>
                </div>
              </div>
              <div class="pt-4 pb-4">
                <p>{{ $t('project.txtClients1') }}</p>
                <p>{{ $t('project.txtClients2') }}</p>
                <p>{{ $t('project.txtClients3') }}</p>
              </div>
            </div>

            <div class="col-12 col-sm-6">
              <h3 class="text-center mt-3">{{ $t('project.forCompanies') }}</h3>
              <div class="d-flex justify-content-around text-center p-3 pb-0">
                <div>
                  <i class="fas fa-cloud-upload-alt" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconPublish') }}</h3>
                </div>
                <div>
                  <i class="far fa-file-alt" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconManage') }}</h3>
                </div>
                <div>
                  <i class="far fa-paper-plane" style="font-size: 4em;"></i>
                  <h3>{{ $t('project.iconPromotion') }}</h3>
                </div>
              </div>
              <div class="pt-4 pb-4">
                <p>{{ $t('project.txtCompanies1') }}</p>
                <p>{{ $t('project.txtCompanies2') }}</p>
                <div class="text-center">
                  <p>{{ $t('project.txtAdBusiness') }}</p>
                  <a href="/shopsuey_companies" class="btn btn-primary">{{ $t('project.btnAdBusiness') }}</a>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <div class="spacer_sm"></div>

        <div class="col-12">
          <h2>{{ $t('project.titDatabase') }}</h2>
          <p>{{ $t('project.txtDatabase1') }}</p>
          <p>{{ $t('project.txtDatabase2') }}</p>
        </div>

        <div class="spacer_sm"></div>
        
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "ShopHome",
  mounted: function(){
    
  },
  methods: {
    
  }

};

</script>
<style scoped>
#arrowdiv{
  height: 50px;
}
.arrowCta {
  display: block;
  height: 13px;
  width: 13px;
  border: 11px solid transparent;
  transform: rotate(45deg);
  position: relative;
  margin: -2vh auto;
}

.arrowCta:after,
.arrowCta:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowCta:after {
  border-bottom: 3px solid #000000;
  border-right: 3px solid #000000;
  top: 0;
  left: 0;
  opacity: 1;
  animation: bottom-arrow 1.65s infinite;
}

@keyframes bottom-arrow {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
}

.arrowCta:before {
  top: 0;
  left: 0;
  border-bottom: 3px solid #000000;
  border-right: 3px solid #000000;
  animation: top-arrow 1.65s infinite;
}

@keyframes top-arrow {
  0% {
    transform: translate(-6px, -6px);
  }
  35% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (max-width: 576px) {
  #arrowdiv{
    display: none;
  }
}
</style>