<template>
    <!-- Modal owner -->
    <div class="modal" id="share_link" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" >
          <div class="modal-header">
            <h2>{{ $t('modal.sharelink.title') }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div id="share_link_div">
              <div class="col-12 p-2">
                <p>{{ $t('modal.sharelink.txtDescription') }}</p>
              </div>
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 col-sm-8 text-center" >
                    <p class="small">{{ $t('modal.sharelink.txtClaim') }}</p>
                    <h3>{{ $t('modal.sharelink.web') }}</h3>
                    <button class="btn btn-primary" id="copyWebsite" @click="shareWebsite();">{{ $t('modal.sharelink.buttonCopy') }}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>

    <!-- End Modal -->
</template>

<script>

export default {
  name: "ShareLink",
  components:{
    
  },
  data(){
    return {
      url: '',
    }
  },
  beforeMount: function() {

  },
  methods: {
    shareWebsite(){
      let copyWebsite = document.getElementById('copyWebsite');
      navigator.clipboard.writeText('https://shopsuey.net/');
      copyWebsite.innerText = "Thank You, Thank You, Thank You!";
      setTimeout(()=> {
        copyWebsite.innerText = 'Copy ShopSuey Link!';
      },2000)

    },
  }
};


</script>