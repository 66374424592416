<template>
    <!-- Modal password -->
    <div class="modal" id="change_password" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" >
          <div class="modal-header">
            <h2>{{ $t('modal.password.title') }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div id="change_password_div">
              <p class="small">{{ $t('modal.password.remember') }}</p>
              <form @submit.prevent="changePassword()" @change="formChanges('password')">
                <div class="col-12 p-4 mb-3 form_box_gray_soft">
                  <div class="row align-items-top justify-content-center">
                    <div class="mb-2 col-sm-12 col-12">
                      <label for="currentPassword" class="form-label">{{ $t('form.labelCurrentPassword') }}</label>
                      <input type="password" class="form-control" id="currentPassword" v-model="passwordForm.currentPassword" />
                    </div>
                    <div class="mb-2 col-12 col-sm-12 col-md-6">
                      <label for="newPassword" class="form-label">{{ $t('form.labelNewPassword') }}</label>
                      <input type="password" class="form-control" id="newPassword" v-model="passwordForm.newPassword" />
                    </div>
                    <div class="mb-2 col-12 col-sm-12 col-md-6">
                      <label for="repeatNewPassword" class="form-label">{{ $t('form.labelRepeatPassword') }}</label>
                      <input type="password" class="form-control" id="repeatNewPassword" v-model="passwordForm.repeatNewPassword" />
                    </div>
                    <div class="col-12 mt-2 text-center">
                      <div class="mb-2 col-12" v-if="submittedPassword && $v.passwordForm.$invalid">
                        <div class="bg-danger text-white rounded p-2 mb-3 text-center" id="error_login">{{ $t('form.errorPasswordInvalid') }}</div>
                      </div>
                      <div class="mb-2 col-12" v-if="submittedPassword && $v.passwordForm.repeatNewPassword.sameAsPassword === false">
                        <div class="bg-danger text-white rounded p-2 mb-3 text-center" id="error_login">{{ $t('form.errorMatchPasswords') }}</div>
                      </div>
                      <div class="col-12">
                        <button type="button" class="btn btn-primary" @click="changePassword()" :disabled="submitPasswordDisabled">{{ $t('form.buttonChangePassword') }}</button>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </form>
            </div>
            <div id="change_password_div_ok" class="d-none">
              <div class="col-12 p-4 mb-3 form_box_gray_soft">
                <div class="row align-items-top justify-content-center">
                  <div class="col-12 text-center">
                    <h3>{{ $t('modal.password.thanks') }}</h3>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >{{ $t('common.buttonCloseWindow') }}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
    </div>

    <!-- End Modal -->
</template>

<script>
import axios from 'axios';
import md5 from 'md5';
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";

// const passchar = helpers.regex('char', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
const testpsw = helpers.regex('testpsw', /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,16}$/);

export default {
  name: "UserHome",
  components:{
    
  },
  validations: {
    passwordForm: {
      currentPassword: {
        required,
        minLength: minLength(2),
        testpsw
      },
      newPassword: {
        required,
        minLength: minLength(2),
        testpsw
      },
      repeatNewPassword: {
        required,
        minLength: minLength(2),
        sameAsPassword: sameAs('newPassword'),
        testpsw
      },
    },
    
  },
  data(){
    return {
      url: '',
      submitUserDisabled: true,
      submitCompanyDisabled: true,
      submitKeywordsDisabled: true,
      submitPasswordDisabled: true,
      
      submittedUserData: false,
      submittedCompanyData: false,
      submittedPassword: false,
      submittedKeywords: false,
      
      submittedResendEmailValidation: false,
      submittedResendEmailOwnVal: false,

      validatedNick: false,
      touchedNick: false,

      touchedLink: false,
      validatedLink: true,

      touchedEmail: false,
      validatedEmail: true,

      domain: '',

      passwordForm: {
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      },
      
    }
  },
  beforeMount: function() {

    
    
  },
  methods: {

    formChanges(a){
      if(a === 'password'){
        this.submitPasswordDisabled = false;
      }
    },
    changePassword(){
      this.submittedPassword = true;

      this.$v.passwordForm.$touch();

      if(this.$v.passwordForm.$invalid == false){

        axios.post('https://shopsuey.net/code/simply/update_password.php',{
          id: this.$session.get('id'),
          current: md5(this.$v.passwordForm.$model.currentPassword),
          new: md5(this.$v.passwordForm.$model.newPassword)
        })
        .then((response) => {

          if(response.data == 'ok'){
            const divKo = document.getElementById('change_password_div');
            const divOk = document.getElementById('change_password_div_ok');
            divKo.remove();
            divOk.classList.remove('d-none');
            this.submittedPassword = false;
            this.submitPasswordDisabled = true;
          }
        })
        .catch(function(error){
          console.log(error);
        })
      }
    },
  }
};


</script>