<template>
  <div class="row align-items-center justify-content-center">
    <div class="col-10">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 text-center" v-if="validated == 1 && submitted == true">
          <h2>Thanks for verify your email</h2>
          <p>Now you are redirecting to ShopSuey home</p>
        </div>
        <div class="col-12 text-center" v-else-if="validated == 2 && submitted == true">
          <h2>This email is already validated</h2>
          <p>Now you are redirecting to ShopSuey home</p>
        </div>
        <div class="col-12 text-center" v-else-if="validated == 0 && submitted == true">
          <h2>We are sorry</h2>
          <p>Something goes wrong!</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: "ValidationTemplate",
  components:{
    
  },
  
  data(){
    return {
      code: '',
      validated: '',
      submitted: false
    }
  },
  beforeMount(){
    this.code = this.$route.params.cd;
    
    axios.post('https://shopsuey.net/code/verifyemail.php',{
      code: this.code
    })
    .then((response) => {
      
      if(response.data == 'ok'){
        this.validated = 1;
        this.submitted = true;
      }else if(response.data == 'validated'){
        this.validated = 2;
        this.submitted = true;
      }else{
        this.validated = 0;
        this.submitted = true;
      }
    })
    .then(() => {
      setTimeout(()=>{
        this.$router.push({name: 'Login'}) 
      },5000);
    })
    .catch(function(error){
      console.log(error);
    });
  },
  mounted(){
    

  },

};

</script>