<template>
  <div class="row align-items-center justify-content-center">
    <div id="loader" class="d-none" style="background: #ffffffb5; width: 100%; height: 100%; text-align: center;position: absolute;z-index: 10000;">
      <div class="spinner-border" role="status" style="position: relative; top: 45%;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="container-fluid bk_shopsuey" id="big-container-content">
      <div class="row align-items-center justify-content-center">
        <div class="col-11">
          
          <div class="mt-3" id="ReginForm">
            <div class="row align-items-center justify-content-center">
              
              <div class="col-12 col-sm-4 mt-2 mt-sm-0 order-2 order-sm-1 text-center">
                <div class="box_white p-4">
                  <p class="text-center text-black"><i class="far fa-thumbs-up" style="font-size:3em;"></i></p>
                  <h2 class="text-black">Come on!</h2>
                  <p class="fs-6 text-black">You can add a link of an online store that you think should be on ShopSuey.</p>
                  <p class="fs-6 text-black">Or if you are the owner, you can create your owner account and follow the steps to verify your ownership.</p>
                  <p class="fs-6 text-black">If your domain already exists in ShopSuey, don't worry, create your account and when you verify the property you will be able to control the keywords, the description and much more.</p>
                </div>  
              </div>

              <div class="col-12 col-sm-8 order-1 order-sm-2">
                <div class="box_black p-4">
                  <h2 class="text-white">Add your link</h2>
                  <!-- form Add Link -->
                  <form @submit.prevent="showmod()">
                    <div class="mb-2">
                      <label for="link" class="form-label text-white">Website link</label>
                      <input type="text" class="form-control" id="link" v-model="regin.link" @change="testLink(regin.link)" />
                      <div class="error_box">
                        <div class="error" v-if="submitted && !$v.regin.link.required">This field is required</div>
                        <div class="error" v-if="submitted && !$v.regin.link.minLength">It's too short</div>
                        <div class="error" v-if="submitted && !$v.regin.link.url">This is not a link</div>
                        <div class="error" v-if="validatedLink == false && touchedLink == true">This link is already in ShopSuey database</div>
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="owner" class="form-label text-white">Are you the link owner?</label>
                      <div class="mt-1 mb-2">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input fs-5" type="radio" name="owner" id="owner1" @change="ownerCheck(true)">
                          <label class="form-check-label text-white fs-5" for="owner1">
                            Yes!
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input fs-5" type="radio" name="owner" id="owner2" @change="ownerCheck(false)" checked>
                          <label class="form-check-label text-white fs-5" for="owner2">
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-12" v-if="regin.owner == true">
                      <h4 class="mt-3 text-white">Create an owner account</h4>
                      <div class="row">
                        <div class="col-12 mt-2">
                          <label for="name" class="form-label text-white">Company name</label>
                          <input type="text" class="form-control" id="name" v-model="regin.name" />
                          <div class="error_box">
                            <div class="error" v-if="submitted && !$v.regin.name.required">This field is required</div>
                            <div class="error" v-if="submitted && !$v.regin.name.minLength">It's too short</div>
                          </div>
                        </div>
                        <div class="col-12 mt-2">
                          <label for="email_verificaton" class="form-label text-white">Email address</label>
                          <input type="email" class="form-control" id="email_verificaton" v-model="regin.email" @change="testEmailUser(regin.email)" />
                          <div class="error_box">
                            <div class="error" v-if="validatedEmail == false && touchedEmailUser == true">This link is already in ShopSuey database.</div>
                            <div class="error" v-if="submitted && !$v.regin.email.required">This field is required</div>
                            <div class="error" v-if="submitted && !$v.regin.email.minLength">It's too short</div>
                            <div class="error" v-if="submitted && !$v.regin.email.email">This is not a email</div>
                          </div>
                        </div>
                        <div class="col-12 mt-2">
                          <label for="password" class="form-label text-white">Password</label>
                          <input type="password" class="form-control" id="password" v-model="regin.password" />
                          
                          <div class="error_box">
                            <div class="error" v-if="submitted && !$v.regin.password.required">This field is required</div>
                            <div class="error" v-if="submitted && !$v.regin.password.minLength">It's too short</div>
                            <div class="error" v-if="submitted && !$v.regin.password.testpsw">Remember! Uppercase, lowercase, numbers. No blanks</div>
                          </div>
                          <div><span class="text-white small">Remember: minimum 8 digits (Uppercase, lowercase and numbers)</span></div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4 text-center">
                      <div class="bg-danger text-white rounded p-2 mb-3 d-none text-center" id="error_login">Password and username do not match. Try again.</div>
                      <button type="submit" class="btn btn-primary btn-lg">Add link</button>
                    </div>
                  </form>
                  <!-- END Add Link -->
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-center box_gray_soft p-3 mt-5">
              <div class="col-6 text-end">
                <p class="mb-0">Have you an account yet?</p>
              </div>
              <div class="col-6 text-start">
                <router-link to="/login" class="btn btn-primary btn-sm">Sign in</router-link>
              </div>
            </div>
          </div>
          <div style="background-color: #f2f2f2; border-radius: 8px" class="col-12 text-center p-4 d-none" id="ReginFormOk">
            <p class="fs-1"><i class="fas fa-thumbs-up"></i></p>
            <h2>Thanks for Sign up!</h2>
            <h4>Now, go to your mailbox and verify your email address account.<br>See you soon!</h4>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { required, email, url, minLength, helpers } from "vuelidate/lib/validators";
const testpsw = helpers.regex('testpsw', /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,16}$/);
// import { VueAutosuggest } from 'vue-autosuggest';
import axios from 'axios';
import md5 from 'md5';

export default {
  name: "ReginTemplate",
  components: { 
    // VueAutosuggest
  },
  validations: {
    regin: {
      name: {
        required,
        minLength: minLength(2)
      },
      link: {
        required,
        minLength: minLength(2),
        url
      },
      email: {
        required,
        minLength: minLength(5),
        email
      },
      password: {
        required,
        minLength: minLength(8),
        testpsw
      }
    }
  },
  
  data(){
    return {
      submitted: false,
      touchedLink: false,
      touchedEmailUser: false,
      validatedLink: false,
      validatedEmail: false,
      regin: {
        name: '',
        link: 'https://',
        email: '',
        password: '',
        owner: false
      },
    }
  },
  computed: {
    filteredOptions() {
      return [
        { 
          data: this.suggestions[0].data.filter(option => {
            return option.name.toLowerCase().indexOf(this.regin.country.toLowerCase()) > -1;
          })
        }
      ];
    }
  },
  beforeMount: function(){
    if(this.$session.exists() === true){
      let nick_session = this.$session.get('nick');
      this.$router.push('/user/' + nick_session);
    }
  },

  mounted: function() {
    // document.getElementById('email_verificaton').focus();
  },

  methods: {
    onSelected(item) {
      this.regin.country = item.item.name;
      this.regin.countryCode = item.item.code;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },
    ownerCheck(e) {
      console.log(e) // FocusEvent
      this.regin.owner = e;
    },

    
    testLink(a){

      let pathArray = a.split( '/' );
      let protocol = pathArray[0];

      if(protocol == 'http:' || protocol == 'https:' && this.$v.regin.link.$invalid == false){
        let host = pathArray[2];
        let url = protocol + '//' + host;
        let hostParts = host.split( '.' );
        
        if (hostParts.length === 2){
          this.regin.link = url;
          this.validatedLink = true;
        }else if(hostParts.length === 3){
          this.regin.link = protocol + '//' + hostParts[0] + '.' + hostParts[1] + '.' + hostParts[2];
          this.validatedLink = true;
        }else if(hostParts.length === 4){
          this.regin.link = protocol + '//' + hostParts[0] + '.' + hostParts[1] + '.' + hostParts[2] + '.' + hostParts[3];
          this.validatedLink = true;
        }else{
          this.validatedLink = false;
        }
      }else{
        this.validatedLink = false;
      }
      
      if(this.validatedLink == true){
        axios.post('https://shopsuey.net/code/testlink.php',{
          link: this.regin.link
        })
        .then((response) => {
          // console.log(response.data.message);
          if(response.data.message == '0'){

            console.log('Cool');
            
          }else{
            this.touchedLink = true;
            this.validatedLink = false;
          }
        })
        .catch(function(error){
          console.log(error);
        });
      }

      

      
      
    },
    testEmailUser(a){

      let validEmail = this.$v.regin.email.email;

      if(validEmail == true){
        axios.post('https://shopsuey.net/code/testemailuser.php',{
          email: a
        })
        .then((response) => {
          
          if(response.data.message == 'ok'){
            this.validatedEmail = true;
          }else if(response.data.message == 'ko'){
            this.validatedEmail = false;
          }
        })
        .then(() => {
          this.touchedEmailUser = true;
        })
        .catch(function(error){
          console.log(error);
        });
      }

    },
    showmod(){

      this.submitted = true;

      this.$v.$touch();

      if(this.validatedLink == true && this.validatedEmail == true && this.$v.$invalid == false){

        let keywordsList = this.$v.regin.$model.keywords.toString();
        let passwordok = md5(this.$v.regin.$model.password);

        axios.post('https://shopsuey.net/code/signup.php',{
          emailuser: this.$v.regin.$model.email,
          password: passwordok,
          nameshop: this.$v.regin.$model.name,
          link: this.$v.regin.$model.link,
          keywords: keywordsList,
          country: this.$v.regin.$model.countryCode,
          product: this.$v.regin.$model.productType,
          description: this.$v.regin.$model.description,
        })
        .then((response)=>{
          if (response.data.message == 'ok'){
            document.getElementById('ReginForm').remove();
            document.getElementById('ReginFormOk').classList.remove('d-none');
          }else{
            console.log(response.data.message);
          }
        })
        .then(function() {
          setTimeout(()=>{
            this.$router.push('/login');
            this.$router.go('/login');
          },10000);
        })
        .catch(function(error){
          console.log(error);
        });  
      }else{
        return false;
      }
    }
  }
};

</script>
