<template>
  <div class="container-fluid" id="big-container-content">
    <div id="loader" class="d-none" style="background: #ffffffb5; width: 100%; height: 100%; text-align: center;position: absolute;z-index: 10000;">
      <div class="spinner-border" role="status" style="position: relative; top: 45%;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="container">

      <div class="row align-items-top justify-content-center">
        
        <div class="col-12 mt-3" id="ReginForm">
          <div class="row align-items-center justify-content-center">
            <div v-if="businessParam == 'cool'" class="col-sm-12 col-md-4 mt-sm-3 mt-md-0 order-sm-2 order-md-1 text-center">
              <div class="box_white p-4">
                <p class="text-center text-black"><i class="far fa-thumbs-up" style="font-size:3em;"></i></p>
                <h2 class="text-black">{{ $t('regin.business_box.textMessage1') }}<br>{{ $t('regin.business_box.textMessage2') }}</h2>
                <div class="separator"></div>
                <h4>{{ $t('regin.business_box.textRemember') }}</h4>
                <h5>{{ $t('regin.business_box.txt1') }}</h5>
                <h5>{{ $t('regin.business_box.txt2') }}</h5>
                <h5>{{ $t('regin.business_box.txt3') }}</h5>
                <div class="separator"></div>
                <p class="mb-2">{{ $t('regin.business_box.textAccount') }}</p>
                <router-link to="/login" class="btn btn-secondary">{{ $t('login') }}</router-link>
              </div>  
            </div>
            <div class="col-sm-12 col-md-8 order-sm-1 order-md-2 box_black p-4">
              <div class="fs-2 text-white mb-4">
                <h2>{{ $t('signupTitle') }}</h2>
                <h4>{{ $t('signupSubtitle') }}</h4>
              </div>

              <form @submit.prevent="signUp()">

                <!-- Part 1 Name -->
                <div id="part_1" class="">
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="name" class="form-label text-white">{{ $t('labelName') }}</label>
                        <input type="text" class="form-control" id="name" v-model="regin.name" autocomplete="off" />
                        <div class="error_box">
                          <div class="error" v-if="formTouched && !$v.regin.name.required">This field is required</div>
                          <div class="error" v-if="formTouched && !$v.regin.name.minLength">It's too short</div>
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <label for="email_verificaton" class="form-label text-white">{{ $t('labelEmail') }}</label>
                        <input type="email" class="form-control" id="email_verificaton" v-model="regin.email" @change="testEmailUser(regin. email)" />
                        <div class="error_box">
                          <div class="error" v-if="emailTouched && validatedEmail == false">This email address is used</div>
                          <div class="error" v-if="formTouched && !$v.regin.email.required">This field is required</div>
                          <div class="error" v-if="formTouched && !$v.regin.email.minLength">It's too short</div>
                          <div class="error" v-if="formTouched && !$v.regin.email.email">This is not a email</div>
                        </div>
                      </div>

                      <div class="col-12 mb-4">
                        <label for="password" class="form-label text-white">{{ $t('labelPsw') }} <span class="text-white small">{{ $t('labelPswRemember') }}</span></label>
                        <input type="password" class="form-control" id="password" v-model="regin.password" />
                        <div class="error_box">
                          <div class="error" v-if="formTouched && !$v.regin.password.required">This field is required</div>
                          <div class="error" v-if="formTouched && !$v.regin.password.minLength">It's too short</div>
                          <div class="error" v-if="formTouched && !$v.regin.password.testpsw">Remember! Uppercase, lowercase, numbers. No blanks</div>
                        </div>
                        
                      </div>

                      <div class="col-12 mb-4">
                        <p class="text-white">{{ $t('labelCompanyQuestion') }}</p>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="company_data" id="flexRadioDefault_yes" v-model="showCompany" value="true">
                          <label class="form-check-label text-white" for="flexRadioDefault_yes">
                            {{ $t('inputYes') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="company_data" id="flexRadioDefault_no" v-model="showCompany" value="false">
                          <label class="form-check-label text-white" for="flexRadioDefault_no">
                            {{ $t('inputNo') }}
                          </label>
                        </div>
                      </div>

                      <div class="col-12" id="company_data_form" v-if="this.showCompany == 'true'">
                        <div class="row align-items-center justify-content-center">
                          <div class="mb-2 col-sm-6 col-12">
                            <label for="name_shop" class="form-label text-white">{{ $t('labelNameShop') }}</label>
                            <input type="text" class="form-control" id="name_shop" v-model="companyForm.name" />
                            <div class="error_box">
                              <div class="error" v-if="formTouched && !$v.companyForm.name.required">This field is required</div>
                              <div class="error" v-if="formTouched && !$v.companyForm.name.minLength">It's too short</div>
                            </div>
                          </div>
                          <div class="mb-2 col-sm-6 col-12">
                            <label for="link_shop" class="form-label text-white">{{ $t('labelLink') }}</label>
                            <input type="text" class="form-control" id="link_shop" v-model="companyForm.link" @change="testLink(companyForm.link)" />
                            <div class="error_box">
                              <div class="error" v-if="formTouched && !$v.companyForm.link.required">This field is required</div>
                              <div class="error" v-if="formTouched && !$v.companyForm.link.minLength">It's too short</div>
                              <div class="error" v-if="formTouched && !$v.companyForm.link.url">This is not a link</div>
                              <div class="error" v-if="touchedLink && validatedLink == false">This link is in ShopSuey</div>
                            </div>
                          </div>
                          <div class=" col-sm-12 col-12 mb-3">
                            <label for="keywords" class="form-label text-white">{{ $t('labelKeywords') }} - <span class="small">{{ $t('labelKeyMessage') }}</span></label>
                            <div class="input-group">
                              <input-tag id="keywords" v-model="companyForm.keywords" :limit="10" class="form-control"></input-tag>
                            </div>
                            <div class="error_box">
                              <div class="error" v-if="formTouched && !$v.companyForm.keywords.required">This field is required</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <div class="mt-2 text-center">
                          <button type="submit" class="btn btn-primary" >{{ $t('buttonCreateAccount') }}</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
          
        </div>
        <div style="background-color: #f2f2f2; border-radius: 8px" class="col-12 text-center p-4 d-none" id="ReginFormOk">
          <p class="fs-1"><i class="fas fa-thumbs-up"></i></p>
          <h2>{{ $t('titleThanksSignup') }}</h2>
          <h4>{{ $t('msgThanksSignup') }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, minLength, helpers, url } from "vuelidate/lib/validators";
const testpsw = helpers.regex('testpsw', /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,16}$/);

import axios from 'axios';
import md5 from 'md5';

export default {
  name: "ReginTemplate",
  components: { 
    
  },
  validations: {
    regin: {
      name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        minLength: minLength(5),
        email
      },
      password: {
        required,
        minLength: minLength(8),
        testpsw
      }
    },
    companyForm: {
      name: {
        required,
        minLength: minLength(2),
      },
      link: {
        required,
        minLength: minLength(2),
        url
      },
      keywords: {
        required
      },
    },
  },
  
  data(){
    return {
      businessParam: '',
      formTouched: false,
      emailTouched: false,

      regin: {
        name: '',
        email: '',
        password: '',
      },
      
      touchedLink: false,
      validatedLink: false,

      showCompany: 'false',
      validatedEmail: false,
      query: "",
      selected: "",

      url: '',
      
      companyForm: {
        name: '',
        link: '',
        keywords: [],
      },
    }
  },
  beforeMount: function(){
    if(this.$session.exists() === true){
      let nick_session = this.$session.get('nick');
      this.$router.push('/user/' + nick_session);
    }else if(this.$route.query.business == 'cool' ){
      this.businessParam = 'cool';
    }

  },

  mounted: function() {
    document.getElementById('name').focus();
  },

  methods: {

    async signUp(){
      this.formTouched = true;

      if(this.$v.regin.$invalid == false && this.validatedEmail == true){
        if(this.showCompany == 'true' && this.$v.companyForm.$invalid == false && this.validatedLink == true){
          
          const response = await this.signupUser();

          if(response == 'ok'){
            let divForm = document.getElementById('ReginForm');
            let divFormOk = document.getElementById('ReginFormOk');
            divForm.remove();
            divFormOk.classList.remove('d-none');
          }
        }else if(this.showCompany == 'false'){
          
          const response = await this.signupUser();

          if(response == 'ok'){
            let divForm = document.getElementById('ReginForm');
            let divFormOk = document.getElementById('ReginFormOk');
            divForm.remove();
            divFormOk.classList.remove('d-none');
          }
        }
      }

    },

    async signupUser() {
      try {
        const idUserData = await this.reginUser(); // parte 1.1 - registrar usuario
        const emailValResponse = await this.emailValidator(idUserData); // parte 1.2 - crear email validation
        if(emailValResponse == 'ok'){
          this.sendEmailValidation(idUserData); // parte 1.3 - enviar email validation
        }

        if(this.showCompany == 'true') {
          const idCompanyData = await this.reginCompany(idUserData); // parte 2.1 - registrar compañía
          await this.relUserCompany(idUserData, idCompanyData); // parte 2.2 - crear relacion usuario-compañía
          await this.ownValidation(idUserData, idCompanyData); // parte 2.3 - crear owner validation

          if(idUserData != 'ko' && idCompanyData != 'ko'){
            return 'ok';
          }
        }else{
          if(idUserData != 'ko'){
            return 'ok';
          }
        }
        
      } catch (error) {
        console.error('Error en las peticiones encadenadas:', error.message);
      }
    },

    
    async reginUser() { 
      try {

        let userData = [];
        let passwordok = md5(this.$v.regin.$model.password);

        userData.name = this.$v.regin.$model.name;
        userData.email = this.$v.regin.$model.email;
        userData.password = passwordok;

        const response = await axios.post('https://shopsuey.net/code/simply/signup_user.php',{
          name: userData.name,
          email: userData.email,
          password: userData.password,
        });

        let idUser = response.data;

        if(idUser != 'ko'){
          console.log('signup user OK');
        }

        return idUser;
      } catch (error) {
        console.error('Error en la primera petición:', error.message);
        throw error;
      }
    },

    async emailValidator(iduser) { 
      try {
        
        const response = await axios.post('https://shopsuey.net/code/simply/create_email_validator.php',{
          iduser: iduser,
        });

        if(response.data == 'ok'){
          console.log('email val OK');
        }else{
          console.log('email val KO!!!');
        }

        return response.data;

      } catch (error) {
        console.error('Error en la segunda petición:', error.message);
        throw error;
      }
    },

    async sendEmailValidation(iduser) { 
      try {

        const response = await axios.post('https://shopsuey.net/code/simply/send_email_validator.php',{
          iduser: iduser,
        });

        if(response.data == 'ok'){
          console.log('send email val OK');
        }else{
          console.log('send email val KO!!!', response.data);
        }

      } catch (error) {
        console.error('Error en la segunda petición:', error.message);
        throw error;
      }
    },

    async reginCompany(iduser) { 
      try {
        let companyData = [];

        companyData.name = this.$v.companyForm.$model.name;
        companyData.link = this.$v.companyForm.$model.link;
        companyData.keywords = this.$v.companyForm.$model.keywords.toString();

        const response = await axios.post('https://shopsuey.net/code/simply/signup_company.php',{
          iduser: iduser,
          name: companyData.name,
          link: companyData.link,
          keywords: companyData.keywords,
        });

        let idCompany = response.data;
        if(idCompany != 'ko'){
          console.log('signup company OK');
        }
        
        return idCompany;
      } catch (error) {
        console.error('Error en la segunda petición:', error.message);
        throw error;
      }
    },

    async relUserCompany(iduser, idcompany) { 
      try {

        const response = await axios.post('https://shopsuey.net/code/simply/create_rel_user_company.php',{
          iduser: iduser,
          idcompany: idcompany,
        });

        if(response.data == 'ok'){
          console.log('rel store_user OK');
        }else{
          console.log('rel store_user KO!!!');
        }        

      } catch (error) {
        console.error('Error en la segunda petición:', error.message);
        throw error;
      }
    },

    async ownValidation(iduser, idcompany) { 
      try {

        const response = await axios.post('https://shopsuey.net/code/simply/create_own_validator.php',{
          iduser: iduser,
          idcompany: idcompany,
        });

        if(response.data == 'ok'){
          console.log('own val OK');
        }else{
          console.log('own val KO!!!');
        }

      } catch (error) {
        console.error('Error en la segunda petición:', error.message);
        throw error;
      }
    },

  // ----------------------------------------------------------------

    testLink(a){

      let pathArray = a.split( '/' );
      let protocol = pathArray[0];

      // test and build link
      if(protocol != 'https:'){
        let domain = 'https://' + protocol;
        this.companyForm.link = domain;
      }else{
        let host = pathArray[2];
        let url = protocol + '//' + host;
        this.companyForm.link = url;        
      }

      axios.post('https://shopsuey.net/code/simply/test_link_regin.php',{
        link: this.companyForm.link
      })
      .then((response) => {
        if(response.data == '0'){
          this.validatedLink = true;
        }else if(response.data != '0'){
          this.validatedLink = false;
        }
      })
      .then(() => {
        this.touchedLink = true;
      })
      .catch(function(error){
        console.log(error);
      });

    },

    testEmailUser(a){

      let validEmail = this.$v.regin.email.email;

      if(validEmail == true){
        axios.post('https://shopsuey.net/code/simply/test_email_regin.php',{
          email: a
        })
        .then((response) => {
          this.emailTouched = true;
          
          if(response.data == 'ok'){
            this.validatedEmail = true;
          }else if(response.data == 'ko'){
            this.validatedEmail = false;
          }
        })
        .then(() => {
          this.touchedEmailUser = true;
        })
        .catch(function(error){
          console.log(error);
        });
      }

    },
    
  }
};

</script>
